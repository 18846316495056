import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultMain from "layout";
import Section1 from "./sections/section1";
import Section2 from "./sections/section2";
import Section3 from "./sections/section3";
import Section4 from "./sections/section4";
import Section5 from "./sections/section5";
import Section6 from "./sections/section6";
import { RootState } from "store/configStore";
import { API_GetAbout } from "store/actions/contentActions";
import { Helmet } from "react-helmet";

const About: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const dispatch = useDispatch();

  const onRender = async () => {
    setIsLoading(true);
    await dispatch(API_GetAbout());
    setIsLoading(false);
  };

  useEffect(() => {
    onRender();
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>About - amanmicrofinance.com</title>
      </Helmet>

      <DefaultMain>
        <Section1 />
        <Section2 loading={isLoading} />
        <Section3 />
        <Section4 loading={isLoading} />
        <Section5 />
        <Section6 loading={isLoading} />
      </DefaultMain>
    </>
  );
};
export default About;
