import React, { useRef } from "react";
import { Image } from "react-bootstrap";
import ReactOwlCarousel from "react-owl-carousel-rtl";

import OwlCarousel from "react-owl-carousel-rtl";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";
import images from "../../../assets/images";
import HeroCarouselItem from "./HeroCarouselItem";
import { LandingCarouselItem } from "../../../types/index";
import Loader from "components/Loader";

interface IProps {
  loading: boolean;
}

const HeroCarousel: React.FC<IProps> = ({ loading }) => {
  const { landingPage } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const { lang } = useSelector((state: RootState) => state.settingsReducer);


  const heroRef = useRef<ReactOwlCarousel>(null);

  let content = landingPage.slider_data?.map((el: LandingCarouselItem) => (
    <HeroCarouselItem key={el.id} data={el} />
  ));

  const carouselClickHandler = (ev: any) => {
    if (ev.currentTarget.value === "next") heroRef.current!.next(500);
    else heroRef.current!.prev(500);
  };

  return (
    <>
      <OwlCarousel
        className="area-slider__slider"
        id="opening-slider"
        dots={false}
        rewind
        loop
        items={1}
        rtl={lang === "en" ? false : true}
        autoplay
        ref={heroRef}
      >
        {loading ? <Loader /> : content}
      </OwlCarousel>
      <button
        className="hero_carousel_btn --prev"
        value="prev"
        onClick={carouselClickHandler}
      >
        <Image src={images.rightArrow} />
      </button>
      <button
        className="hero_carousel_btn --next"
        value="next"
        onClick={carouselClickHandler}
      >
        <Image src={images.leftArrow} />
      </button>
    </>
  );
};

export default HeroCarousel;
