import { createStore, combineReducers, applyMiddleware } from "redux";

import contentReducer from "./reducers/contentReducer";
import settingsReducer from "./reducers/settingsReducer";
import ReduxThunk from "redux-thunk";

const rootReducer = combineReducers({
  contentReducer,
  settingsReducer,
});

const configureStore = () => {
  return createStore(rootReducer, applyMiddleware(ReduxThunk));
};

export type RootState = ReturnType<typeof rootReducer>;
export const store = configureStore();
export default configureStore;
