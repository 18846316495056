import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./resources/en";
import { ar } from "./resources/ar";
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  .use(LanguageDetector)
  // init i18next
  .init({
    supportedLngs: ["en", "ar"],
    lng: "ar", // default language
    fallbackLng: "ar",
    detection: {
      order: ["htmlTag", "cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    resources: {
      en,
      ar,
    },
  });
export default i18n;
