import React from "react";
import { Image } from "react-bootstrap";
import images from "assets/images";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const HeroHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="area-title__text">
      <Image
        src={images.amanRayaLogo}
        className="area-title__logo"
        alt="logo"
        width={300}
      />
      <h1 className="f-xxl font bold">{t("Fund and get done with aman")}</h1>
      <p className="f-sm font light pt-3 pb-4 secandary-text-sec-1">
        {t(
          "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector"
        )}
      </p>
      <div className="actions">
        <NavLink to="/about-us" className="amnmf-btn-filled font bold f-sm">
          {t("Read more about us")}
        </NavLink>
        <NavLink to="/branches" className="amnmf-btn-outlined font bold f-sm">
          {t("Find the nearest branch")}
        </NavLink>
      </div>
    </div>
  );
};

export default HeroHeader;
