import images from "../assets/images";

export const loanRequestContent = {
  modalTitle: `Terms and Guidelines for Getting Started with Amana`,
  header: `General conditions and features to be available in the client`,
  header2: `Important Alert`,
  header3: `The ability to pay through a network of Aman branches, electronic payment methods and at the nearest place to you.`,
  btnText: `Have questions? Read the answers to the most frequently asked questions about Amanah financing`,
  btnText2: `Apply for financing now`,
  carouselHeader: `Loan request application form`,
  formSubmitBtn: `Apply request`,
};

export const loanRequestRulesSME= [
`A recent and valid commercial register.`,
`The tax card is valid.`,
`Customer and guarantor card.`,
`Documents of ownership or lease of the headquarters and documents of branches or warehouses.`,
`The company's articles of incorporation, amendment contracts, or company newspaper.`,
`The last 3 budgets.`,
`The credentials of the chartered accountant`,
`Bank account statement.`,
`A statement of the tax and insurance position`,
`The latest social security payment receipt.`,
`Activity license.`,
`Basic data certificate from the tax authority.`,
`Supply orders, valid contracts, or withdrawal statements`,
`bankruptcy and protesto`,
`Recruitment certificate for clients under 30 years old`,
`Combined statement and related parties`
];
export const loanRequestRules = [
  `To be of Egyptian nationality.`,
  `The age ranges from 21 to 63 years.`,
  `That the client has an existing income-generating activity that satisfies the payment of the finance amount and its charges.`,
  `That the customer enjoys a good reputation through the prepared credit inquiry and the field inquiry through the place of residence and the place where the activity is carried out.`,
  `To have a valid National Number Card.`,
  `Not to be a guarantor for one of the company's existing clients.`,
  `To have a guarantor who has a good reputation from one of his first-degree relatives (father - mother - wife - son - daughter).In the event that a first-degree relative is not available, the guarantor is a person with a fixed income (employee or pensioner).`,
  `The client must provide evidence of his place of business and his permanent residence.`,
];

export const importantNotes = [
  `Be sure to get the payment card at the time of exchange and a receipt indicating the payment when paying cash inside our branches.`,
  `Be sure to deal with company employees only.`,
  "Applying for funding is free.",
];

export const loanRequestCarousel = [
  images.carousel0,
  images.carousel1,
  images.carousel2,
  images.carousel3,
  images.carousel4,
  images.carousel5,
  images.carousel6,
  images.carousel7,
  images.carousel8,
  images.carousel9,
  //   images.carousel10,
];
