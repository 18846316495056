import React, { useEffect, useState } from "react";
import DefaultMain from "layout";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RootState } from "store/configStore";
import { useDispatch, useSelector } from "react-redux";
import { API_GetFAQ } from "store/actions/contentActions";
import images from "assets/images";
import CircleIconsRow from "./components/CircleIconsRow";
import FaqTabs from "./components/FaqTabs";
import { imgs } from "../../content/faq";
import { Helmet } from "react-helmet";

const Branches: React.FC = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { faqs } = useSelector((state: RootState) => state.contentReducer);
  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const dispatch = useDispatch();
  const onRender = async () => {
    setIsLoading(true);
    await dispatch(API_GetFAQ());
    setIsLoading(false);
  };
  useEffect(() => {
    onRender();
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>Contact Us - amanmicrofinance.com</title>
      </Helmet>

      <DefaultMain>
        <section>
          <Container className="faq-container" fluid>
            <Row>
              <Col className="faq-side" md={3}>
                <img
                  src={images.orangetrin}
                  className="orangeTrin"
                  width="30"
                  height="30"
                  alt=""
                />
                <h2 className="font bold">{t("questions and answers")}</h2>
                <p className="font light">
                  {t(
                    "A set of frequently asked questions about Aman financing, terms and conditions, and how to pay"
                  )}
                </p>
              </Col>
              {isLoading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="info" />
                </div>
              ) : (
                <Col className="faq-box">
                  <Container className="faq-tabs-container">
                    <CircleIconsRow icons={imgs} />
                    <FaqTabs faqs={faqs} nano={false} />
                  </Container>
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </DefaultMain>
    </>
  );
};
export default Branches;
