import images from "assets/images";
import { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "../assets/images/close-cyan@3x.png";
interface Props {
  show: boolean;
  handleClose?: any;
  isSuccess: boolean;
  successText: string;
  errorText: string;
}
const CommonModal: FC<Props> = ({
  show,
  handleClose,
  successText,
  errorText,
  isSuccess,
}) => {
  const { t } = useTranslation();
  return (
    <Modal className="notify-modal" show={show} onHide={handleClose}>
      {/* <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header> */}

      <Modal.Body className="notify-modal__body">
        <button
          onClick={() => {
            handleClose(false);
          }}
        >
          <img
            className="close-button-modal"
            src={images.closeIcon}
            width={30}
            height={30}
          />
        </button>
        {isSuccess ? (
          <>
            <img
              className="success-img"
              src={images.checkMark}
              width={150}
              height={150}
            />
            <p>{t(successText)}</p>
          </>
        ) : (
          <>
            <img
              className="error-img"
              src={images.errorIcon}
              width={100}
              height={100}
            />
            <p>{t(errorText)}</p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CommonModal;
