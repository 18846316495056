import images from "assets/images";
import { useTranslation } from "react-i18next";
const Section1: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="aboutAman__header__section d-flex flex-wrap">
      <div className="aboutAman__header__section__content col-xl-5 col-md-12 pt-5">
        <h3 className="text-white font bold mb-3">{t("About Aman")}</h3>
        <h2 className="text-white font bold mb-5">
          {t(
            "The latest company to provide microfinance services in Egypt with the highest technological systems and programs"
          )}
        </h2>
        <p className="text-white font light mb-5">
          {t(
            "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector."
          )}
        </p>
        <ul className="text-white d-flex py-5">
          <li className="font bold mx-3">
            <a href={"#aboutAman"} className={"nav-anchor-link"}>
              {t("About aman")}
            </a>
          </li>
          <li className="font bold mx-3">
            <a href={"#vision"} className={"nav-anchor-link"}>
              {t("Vision")}
            </a>
          </li>
          <li className="font bold mx-3">
            <a href={"#teamMembers"} className={"nav-anchor-link"}>
              {t("Administration")}
            </a>
          </li>
        </ul>
      </div>
      <div className="aboutAman__header__section__imgs col-xl-7 col-md-12">
        <img src={images.amanStore} alt="Aman team" className="w-100" />
        <div className="img__overlay"></div>
        <img src={images.triangle} alt="" />
      </div>
    </section>
  );
};
export default Section1;
