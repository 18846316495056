import React, { useState } from "react";
import { Container, Col, Modal, Image } from "react-bootstrap";
import {
  loanRequestContent,
  loanRequestRules,
  importantNotes,
  loanRequestRulesSME
} from "content/loan_request";
import images from "assets/images";
import HeaderWithLine from "components/HeaderWithLine";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";


interface Props {
  type:string
}
const LoanRequestModal: React.FC<Props> = (props) => {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  let loanRequestRulesList
    if(props.type=="sme"){
    loanRequestRulesList = loanRequestRulesSME.map((rule, i) => {
      if (i === 2)
        return (
          <li className="font bold" key={i}>
            {t(rule)}
          </li>
        );
      return (
        <li className="font light" key={i}>
          {t(rule)}
        </li>
      );
    });
  }else{
   loanRequestRulesList = loanRequestRules.map((rule, i) => {
    if (i === 2)
      return (
        <li className="font bold" key={i}>
          {t(rule)}
        </li>
      );
    return (
      <li className="font light" key={i}>
        {t(rule)}
      </li>
    );
  });
}


  let notes = importantNotes.map((n, i) => (
    <p className="font light" key={i}>
      {t(n)}
    </p>
  ));

  return (
    <Modal
      show={show}
      fullscreen={true}
      onHide={() => setShow(false)}
      className="loan_request_modal"
    >
      <Modal.Body className="p-0 rounded">
        <Col className="policy-sec" md={12}>
          <HeaderWithLine text={t(loanRequestContent.modalTitle)} count={2} />
          <Image width={50} height={50} alt="50x50" src={images.policyIcon1} />
          <h3 className="font bold">{t(loanRequestContent.header)}</h3>
          <ol className="font light">{loanRequestRulesList}</ol>
          <Image width={50} height={50} alt="50x50" src={images.policyIcon2} />
          <h3 className="font bold">{t(loanRequestContent.header2)}</h3>
          {notes}
          <Image
            width={50}
            height={50}
            alt="50x50"
            className="modal-map-icon"
            src={images.policyIcon3}
          />
          <h3 className="font light">{t(loanRequestContent.header3)}</h3>
          <Container className="loan_request_btn">
            <NavLink to="/faq" className="font light">
              {t(loanRequestContent.btnText)}
              <Image src={images.orangeArrow} width="35" height="35" />
            </NavLink>
          </Container>
          <button
            onClick={() => {
              setShow(false);
            }}
            className="font bold btn"
          >
            {t(loanRequestContent.btnText2)}
          </button>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default LoanRequestModal;
