import React from "react";
import DefaultMain from "layout";
import CareersFormBanner from "./sections/careersFormBanner";
import CareersFormMain from "./sections/careersFormMain";

const CareersForm: React.FC = (props) => {
  return (
    <DefaultMain>
      <CareersFormBanner />
      <CareersFormMain />
    </DefaultMain>
  );
};
export default CareersForm;
