import React from "react";
import images from "assets/images";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { API_getUserProtectionGuide } from "store/actions/contentActions";
import { useDispatch } from "react-redux";
const Footer: React.FC = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <footer className="footer__section">
      <div className="footer__firstSide">
        <p className="font light firstSide__copyRight">
          <span>{t("All rights reserved © 2021")}</span>
        </p>
        <div className="footer__firstSide__content">
          <figure className="footer__firstSide__img">
            <img src={images.amanLogo} alt="aman footer logo" />
          </figure>
          <h3 className="font bold">
            {t("The financing is on us and the project is on you")}
          </h3>
          <p className="font light">
            {t(
              "Quick procedures for disbursing funding through the use of modern technology in disbursement"
            )}
          </p>
          <ul className="footer__firstSide__list">
            <li className="font bold">
              <a href="https://rayacorp.com/" target={"_blank"}>
                {t("About Raya")}
              </a>
            </li>
            <li className="font bold">
              <Link to="/about-us">{t("About Aman")} </Link>
            </li>
            <li className="font bold">
              <Link to="/news">{t("Our News")}</Link>
            </li>
          </ul>
        </div>
        <div className="footer__firstSide__bottomImg">
          <img src={images.triangle} alt="rav footer logo" />
        </div>
        <ul className="footer__firstSide_list__icons">
          <li>
            <a
              href="https://www.facebook.com/AmanforMicrofinance"
              target="_blank"
            >
              <img src={images.facebook} alt="facebook-img" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UC2aKVBF0VNpLMbUv5TkkIcQ"
              target="_blank"
            >
              <img src={images.youtube} alt="youtube-img" />
            </a>
          </li>
          <li>
            <a
              href="https://web.whatsapp.com/send/?phone=20219910&text=hello&type=phone_number&app_absent=0"
              target="_blank"
            >
              <img src={images.whatsapp} alt="whatsapp-img" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/aman-holding-for-financials/"
              target="_blank"
            >
              <img src={images.linkedin} alt="linkedin-img" />
            </a>
          </li>
        </ul>
      </div>
      <div className="footer__secondSide">
        <div className="footer__socialContacts__icons">
          <ul className="footer__list__icons">
            <li>
              <a
                href="https://www.facebook.com/AmanforMicrofinance"
                target="_blank"
              >
                <img src={images.facebook} alt="facebook-img" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC2aKVBF0VNpLMbUv5TkkIcQ"
                target="_blank"
              >
                <img src={images.youtube} alt="youtube-img" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/aman-holding-for-financials/"
                target="_blank"
              >
                <img src={images.linkedin} alt="linkedin-img" />
              </a>
            </li>
            <li>
              <a
                href="https://web.whatsapp.com/send/?phone=20219910&text=hello&type=phone_number&app_absent=0"
                target="_blank"
              >
                <img src={images.whatsapp} alt="whatsapp-img" />
              </a>
            </li>
          </ul>
          <div className="footer__copyright">
            <p className="font light">{t("All rights reserved © 2021")}</p>
            <p className="font light">
              <a href="https://www.uniparticle.com/home">
                {" "}
                <span className="font bold">uniparticle</span>
              </a>{" "}
              {t("Design and Implementation")}
            </p>
          </div>
        </div>
        <div className="footer__secondSide__upperItems">
          <ul className="footer__secondSide__upperItem">
            <h4 className="font bold">{t("More About Us")}</h4>
            <li className="font light">
              <Link to="/success-stories">{t("Aman success stories")}</Link>
            </li>
            <li className="font light">
              <Link to="/branches">{t("Aman Branches")} </Link>
            </li>
            <li className="font light">
              <Link to="/services">{t("Our Services")}</Link>
            </li>
          </ul>

          <ul className="footer__secondSide__upperItem">
            <h4 className="font bold">{t("Financing Programs")}</h4>

            <li className="font light">
              <NavLink to={"/loan-request-online"} className="font light">
                <p className="font light">{t("online merchants")}</p>
              </NavLink>
            </li>
            <li className="font light">
              <NavLink to={"/loan-request-sme"}>
                <p className="font light">{t("Small and medium finance")}</p>
              </NavLink>
            </li>
            <li className="font light">
              <NavLink to={"/loan-request-mf"}>
                <p className="font light">{t("micro finance")}</p>
              </NavLink>
            </li>
            <li className="font light">
              <NavLink to={"/nano-lending"}>
                <p className="font light">{t("nano lending")}</p>
              </NavLink>
            </li>
            <li className="font light">
              <NavLink to={"/islamic-loan"}>
                <p className="font light">{t("Islamic Loan")}</p>
              </NavLink>
            </li>
          </ul>
          <ul className="footer__secondSide__upperItem">
            <h4 className="font bold">{t("Aman Store")}</h4>
            <li className="font light">
              <a href="https://www.amanstores.com/" target={"_blank"}>
                {t("Browse Aman Store")}
              </a>
            </li>
            {/* <li className="font light">
              <a href="#">{t("Categories")}</a>
            </li> */}
          </ul>
          <ul className="footer__secondSide__upperItem">
            <h4 className="font bold">{t("Join Us")}</h4>
            <li className="font light">
              <Link to="/careers">{t("jobs")}</Link>
            </li>
          </ul>
          <ul className="footer__secondSide__upperItem">
            <h4 className="font bold">{t("Contact Us")}</h4>
            <li className="font light">
              <Link to="/contact-us">{t("Send a message")}</Link>
            </li>
          </ul>
        </div>
        <div className="footer__secondSide__bottomItems">
          <ul className="footer__secondSide__bottomItems__list">
            <li className="font light">
              <Link to="/privacy">{t("Privacy Policy")}</Link>
            </li>
            <li
              onClick={() => dispatch(API_getUserProtectionGuide())}
              style={{ cursor: "pointer" }}
            >
              <a className="font light">
                {t("Guide to protect customers in the non-banking sector")}{" "}
              </a>
            </li>
          </ul>
          <a
            href="https://play.google.com/store/apps/details?id=com.amanmf&pli=1"
            target="_blank"
          >
            <img src={images.googlePlay} alt="googlePlay-img" />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
