import images from "assets/images";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StoryModal: React.FC<{
  videoUrl: string;
  className?: string;
  name: string;
}> = ({ videoUrl, children, className, name }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button onClick={handleShow} className={className}>
        {children}
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        className="success-stories__modal"
      >
        <Modal.Header closeButton>
          <img
            className="orange-arrow"
            src={images.orangetrin}
            alt="orange arrow"
          />
          <h3 className="font bold">{t("Inspirational stories")}</h3>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="1436"
            height="807"
            src={videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <div className="name-wrapper">
            <h5 className="font bold">{name}</h5>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoryModal;
