import images from "assets/images";
import { SyntheticEvent } from "react";
import { useAccordionButton } from "react-bootstrap";

interface ICustomProps {
  eventKey: any;
  accordionCollapse: boolean;
  isCollapsed: boolean;
  onClick: (event: SyntheticEvent) => void;
}

//toggle icon in accordion
function CustomToggle(props: ICustomProps) {
  const decoratedOnClick = useAccordionButton(props.eventKey);

  const handleCollapse = (event: SyntheticEvent) => {
    decoratedOnClick(props.eventKey);
    props.onClick(event);
  };

  return (
    <button onClick={handleCollapse} className={"mx-4"}>
      <img
        src={
          props.accordionCollapse || props.isCollapsed
            ? images.collapsePlus
            : images.collapseMinus
        }
        alt={"collapse"}
      />
    </button>
  );
}

export default CustomToggle;
