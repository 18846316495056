import React from "react";
import DefaultMain from "layout";
import Section1 from "./sections/section1";

const Installments: React.FC = (props) => {
  return (
    <DefaultMain>
      <Section1 />
    </DefaultMain>
  );
};
export default Installments;
