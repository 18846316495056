import React from "react";
import images from "assets/images";
import { startProjectContent } from "content/landing";
import FinancialGrowShapeAnimated from "components/FinancialGrowShapeAnimated";
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "store/configStore";
const Section3: React.FC = (props) => {
  const { t } = useTranslation();
  const { constants } = useSelector(
    (state: RootState) => state.contentReducer
  );
  return (
    <section className=" amanmf__start-project m-0 position-relative">
      <div className="amanmf__start-project__title">
        <div className="title-with-kite">
          <h2 className="font bold f-xxl text-center">
            <Image
              src={images.kiteArrow}
              width={140}
              height={140}
              alt="140x140"
            />
            {t(startProjectContent.title)}
          </h2>
        </div>
        <h3 className="font light f-xl text-center">
          {t(startProjectContent.details)}
        </h3>
      </div>
      <div className="amanmf__image-container">
        <div className="amount-range">
          <div className="f-xl font bold box box__orange">
            {t("start with")+" "+ constants.min_loan_amount+" "+t('LE')}
          </div>
          <div className="f-xl font bold box box__dark-orange">
            {t("to")+" "+ constants.max_loan_amount +" "+ t('million')}
          </div>
        </div>
        <FinancialGrowShapeAnimated />
      </div>
    </section>
  );
};
export default Section3;
