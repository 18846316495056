import React, { useEffect } from "react";
import DefaultMain from "layout";
import { Container, Row } from "react-bootstrap";
import BranchesList from "./components/BranchesList";
import { NavLink } from "react-router-dom";
import images from "assets/images";
import { initMap } from "helper/mapModule";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "store/configStore";
import { Helmet } from "react-helmet";

const Branches: React.FC = (props) => {
  const { t } = useTranslation();
  const { governments } = useSelector(
    (state: RootState) => state.contentReducer
  );

  useEffect(() => {
    initMap(governments);
  }, [governments]);

  return (
    <>
      <Helmet>
        <title>Branches - amanmicrofinance.com</title>
      </Helmet>
      <DefaultMain>
        <section>
          <Container className="branches-container" fluid>
            <div className="branches-list">
              <h3 className="font bold">
                <span className="bg-line">
                  {t(`Aman microfinance branches`)}
                </span>
              </h3>
              <Row>
                <BranchesList />
              </Row>
              <Row className="branches_btn_container">
                <NavLink to={"/loan-request-mf"} className="font light">
                  {t("Request your funding now")}
                  <img src={images.orangeArrow} width="35" height="35" alt="" />
                </NavLink>
              </Row>
            </div>
            {/* <div id="map"></div> */}
          </Container>
        </section>
      </DefaultMain>
    </>
  );
};
export default Branches;
