import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultMain from "layout";
import Loader from "components/Loader";
import Section1 from "./sections/section1";
import { RootState } from "store/configStore";
import { API_GetPolicy } from "store/actions/contentActions";
import { Helmet } from "react-helmet";

const Privacy: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { lang } = useSelector((state: RootState) => state.settingsReducer);

  const dispatch = useDispatch();

  const onRender = async () => {
    setIsLoading(true);
    await dispatch(API_GetPolicy());
    setIsLoading(false);
  };

  useEffect(() => {
    onRender();
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>Privacy - amanmicrofinance.com</title>
      </Helmet>
      <DefaultMain>{isLoading ? <Loader /> : <Section1 />}</DefaultMain>
    </>
  );
};
export default Privacy;
