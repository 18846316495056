import React, { useRef, useState } from "react";

import DefaultMain from "layout";
import images from "assets/images";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { altsapiBaseUrl } from "netwrok/baseUrl";
import { Spinner } from "react-bootstrap";

const SmesRepayment = (props) => {
  //  LOCAL STATE
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLloading, setSubmitLoading] = useState(false);
  const [taxNumber, setTaxNumber] = useState("");
  const [branchId, setBranchId] = useState("");
  const [step, setStep] = useState(1);
  const [fileUploaded, setFileUploaded] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [receiptDate, setReceiptDate] = useState("");
  const [amount, setAmount] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");

  // HOOKS
  const { t } = useTranslation();

  // METHODS
  const API_auth = () => {
    return axios({
      url: `${altsapiBaseUrl}/Account/authenticate`,
      method: "POST",
      data: {
      },
    });
  };
  const API_checkOrganization = async () => {
    setLoading(true);
    const response = await API_auth();
    console.log("AUTH RESPONSE", response);
    const { token } = response.data;
    axios({
      url: `${altsapiBaseUrl}/SmeRepayment/CheckOrganization`,
      method: "GET",
      params: {
        TaxNumber: taxNumber,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (res) => {
        console.log("CHECK ORNIZATION SUCCESS: ", res);
        setLoading(false);
        if (res.data.isConfirmed && res.data.success) {
          setStep(2);
          setBranchId(res.data.branchId);
          setCustomerNumber(res.data.customerNumber);
        } else {
          setError(true);
        }
      },
      (err) => {
        console.log("CHECK ORNIZATION FAILED:", err);
        setError(true);
        setLoading(false);
      }
    );
  };
  const API_addReceiptInfo = async (data) => {
    const response = await API_auth();
    console.log("AUTH RESPONSE", response);
    const { token } = response.data;
    axios({
      url: `${altsapiBaseUrl}/SmeRepayment/AddReceiptInfo`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    }).then(
      (res) => {
        console.log("ADD RECEIPT INFO SUCCESS:", res);
        if (res.data.success) {
          setStep(3);
        }
        setSubmitLoading(false);
      },
      (err) => {
        console.log("ADD Reciept info FAiled", err);
        setSubmitLoading(false);
      }
    );
  };
  const isSubmitDisabled = () => !customerNumber || !receiptNumber || !receiptDate || !amount || !fileBase64 || submitLloading;

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSubmitLoading(true);
    API_addReceiptInfo({
      customerNumber,
      receiptNumber,
      receiptDate,
      amount,
      BranchId: branchId,
      ReferenceNumber: referenceNumber,
      documentAsBase64: fileBase64.split("base64,")[1],
    });
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        console.log("FILE READER", fileReader.result);
      };
      fileReader.onerror = () => {
        reject(error);
      };
    });
  };
  const onFileUploaded = async (e) => {
    console.log("FILE UPLODED: ", e.target.files);
    const file = e.target.files[0];
    setFileUploaded(file);
    if (!file) return;
    const base64 = await convertToBase64(file);
    setFileBase64(base64);
  };
  const deleteDocument = (e) => {
    e.stopPropagation();
    setFileBase64("");
  };
  return (
    <DefaultMain>
      <div className="smes-repayment">
        <div className="sme-card">
          {step === 1 && (
            <div className="step-1">
              <div className="sme-header">
                <div className="title">
                  <img src={images.orangetrin} />
                  <h2 className="font bold">{t("SMEs Repayments")}</h2>
                </div>
                <p className="font light">{t("SMEs Repayment text")}</p>
              </div>
              <div className="sme-body">
                <div className="title-container">
                  <p className="font bold">{t("Bank info")}</p>
                  <div className="orange-line"></div>
                </div>
                <div className="info-card">
                  <div className="img-container">
                    <img src={images.icon_orange_bacnk_name} />
                  </div>
                  <p className="font light label">{t("Bank Name")}</p>
                  <p className="font bold value">{t("NBE")}</p>
                </div>
                <div className="info-card">
                  <div className="img-container">
                    <img src={images.icon_orange_user_name} />
                  </div>
                  <p className="font light label">{t("Beneficiary Name")}</p>
                  <p className="font bold value">{t("AMAN micro finance")}</p>
                </div>
                <div className="info-card">
                  <div className="img-container">
                    <img src={images.icon_orange_location} />
                  </div>
                  <p className="font light label">{t("Beneficiary address")}</p>
                  <p className="font bold value">{t("Beneficiary address value")}</p>
                </div>
                <div className="info-card">
                  <div className="img-container">
                    <img src={images.icon_orange_account_number} />
                  </div>
                  <p className="font light label">{t("Beneficiary account number")}</p>
                  <p className="font bold value">{"1882310912749000010"}</p>
                </div>
                <div className="info-card">
                  <div className="img-container">
                    <img src={images.icon_orange_international_account_number} />
                  </div>
                  <p className="font light label">{t("International Account Number (IBAN)")}</p>
                  <p className="font bold value">{"EG640003018823109127490000100"}</p>
                </div>
                <div className="info-card">
                  <div className="img-container">
                    <img src={images.icon_orange_swift} />
                  </div>
                  <p className="font light label">{t("Swift code")}</p>
                  <p className="font bold value">{"NBEGEGCX188"}</p>
                </div>
                <p className="font bold" style={{ fontSize: "16px", marginBottom: '15px' }}>
                  {t("Tax card number")}
                </p>
                {error && <p className="font light" style={{color: '#C83D40', fontSize: '16px'}}>لا يوجد بيانات مسجلة</p>}
                <div className="my-row font" style={{marginTop: '10px'}}>
                  <div style={{ width: "100%" }}>
                    <input
                      value={taxNumber}
                      maxLength={11}
                      onChange={(e) => {
                        setError(false);
                        const lastChar = e.target.value[e.target.value.length - 1];
                        console.log("last chat:", lastChar, lastChar === "-");
                        if (e.target.value.length == 4 || e.target.value.length == 8) {
                          if (lastChar == "-") {
                            setTaxNumber(e.target.value);
                          } else {
                            setTaxNumber(taxNumber + "-" + lastChar);
                          }
                        } else {
                          setTaxNumber(e.target.value);
                        }
                      }}
                      style={{ borderColor: error ? "#C83D40" : " #484848" }}
                      type="text"
                      placeholder={t("Enter the tax card number")}
                    />
                  </div>
                  <button className="font bold" disabled={!taxNumber || loading} onClick={API_checkOrganization}>
                    {loading ? <Spinner animation="border" color="white" /> : t("Confirm")}
                  </button>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="step-2">
              <div className="sme-header">
                <div className="title-container">
                  <p className="font bold">{t("Personal Info")}</p>
                  <div className="orange-line"></div>
                </div>
                <button
                  className="back-button"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  <p className="font light">{t("Back")}</p>
                  <img src={images.icon_black_back_arrow} />
                </button>
              </div>
              <div className="sme-body">
                <div className="input-container" style={{ display: fileBase64 ? "none" : "flex" }}>
                  <label htmlFor="upload" className="font bold">
                    <p>رفع الايصال</p>
                    <div className="file-input-placeholder">
                      <img src={images.icon_recipt} />
                      <p className="font light">رفع ايصال الايداع</p>
                    </div>
                  </label>
                  <input type="file" id="upload" accept="image/*" onChange={onFileUploaded} />
                </div>
                <div style={{ width: "90%", display: !fileBase64 ? "none" : "initial" }}>
                  <p className="font bold">الايصال المرفوع</p>
                  <div className="file-input-result">
                    <div className="my-row">
                      <img width={18} height={25} style={{ objectFit: "contain", marginLeft: "5px" }} src={fileBase64} />
                      <p className="font light">{fileUploaded.name}</p>
                    </div>
                    <div className="my-row">
                      <button className="action-btn" onClick={deleteDocument}>
                        <img src={images.icon_trash_bin} />
                      </button>
                      <button
                        className="action-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          document.getElementById("upload").click();
                          // fileInputRef.current.click();
                        }}
                      >
                        <img src={images.icon_edit_pen} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  <label className="font bold" htmlFor="client-code">
                    كود العميل
                  </label>
                  <input
                    type="text"
                    value={customerNumber}
                    onChange={(e) => {
                      setCustomerNumber(e.target.value);
                    }}
                    className="input font light"
                    id="client-code"
                    placeholder="ادخل الكود الخاص بك"
                  />
                </div>
                <div className="input-container">
                  <label className="font bold" htmlFor="recept-numper">
                    رقم الايصال
                  </label>
                  <input
                    type="text"
                    value={receiptNumber}
                    onChange={(e) => {
                      setReceiptNumber(e.target.value);
                    }}
                    className="input font light"
                    id="recept-number"
                    placeholder="ادخل الكود الخاص بك"
                  />
                </div>
                <div className="input-container">
                  <label className="font bold" htmlFor="recept-data">
                    تاريخ الايصال
                  </label>
                  <input
                    type="date"
                    value={receiptDate}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setReceiptDate(e.target.value);
                    }}
                    className="input font light"
                    id="recept-date"
                    placeholder="yyyy-MM-dd"
                  />
                </div>
                <div className="input-container">
                  <label className="font bold" htmlFor="amount">
                    مبلغ الايداع
                  </label>
                  <input
                    type="text"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    className="input font light"
                    id="amount"
                    placeholder="ادخل مبلغ الايداع"
                  />
                </div>
                <div className="input-container">
                  <label className="font bold" htmlFor="recept-numper">
                    الرقم المرجعى
                  </label>
                  <input
                    type="text"
                    value={referenceNumber}
                    onChange={(e) => {
                      setReferenceNumber(e.target.value);
                    }}
                    className="input font light"
                    id="recept-number"
                    placeholder="ادخل الرقم المرجعى"
                  />
                </div>

                <button type="submit" className="submit-btn" disabled={isSubmitDisabled()} onClick={submitForm}>
                  {submitLloading ? <Spinner animation="border" color="white" /> : <p className="font bold">ارسل التفاصيل</p>}
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="step-3">
              <img className="success-image" src={images.visual_success} />
              <p className="font bold" style={{fontSize: '32px', marginTop: '30px'}}>عملية ناجحة</p>
              <p className="font light" style={{fontSize: '16px', marginTop: '16px'}}>تم رفع ايصال السداد بنجاح شكرا لك</p>
            </div>
          )}
        </div>
      </div>
    </DefaultMain>
  );
};

export default SmesRepayment;
