import ReactPaginate from "react-paginate";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NewsItem } from "types";
import images from "assets/images";
import Loader from "components/Loader";
import NewsRow from "../components/NewsRow";
import { RootState } from "store/configStore";
import { API_GetNews } from "store/actions/contentActions";
interface IProps {
  news: [NewsItem];
}

const LatestNews: React.FC<IProps> = ({ news }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { newsPageCount } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const { lang } = useSelector((state: RootState) => state.settingsReducer);

  const [selected, setSelected] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [bankRelated, setBankRelated] = useState(false);

  const handleSelection = (value: string) => {
    setSelected(value);
    if (value === "2") {
      setBankRelated(true);
    } else if (value === "3") {
      setBankRelated(false);
    }
  };

  const handlePageClick = (event: any) => {
    const pageNumber = event.selected + 1;
    if (pageNumber <= newsPageCount) {
      setCurrentPage(pageNumber);
    }
  };

  const onRender = async () => {
    setIsLoading(true);
    selected === "1"
      ? await dispatch(API_GetNews(currentPage, false))
      : await dispatch(API_GetNews(currentPage, false, bankRelated));
    setIsLoading(false);
  };

  useEffect(() => {
    onRender();
  }, [currentPage, bankRelated, selected]);

  return (
    <section className="latest-news__section">
      <Row className="latest-news__header">
        <h2 className="font bold">{t("Latest News")}</h2>
        <div className="news-select-container">
          <img
            src={images.dropdown}
            alt={"dropdown icon"}
            className={`news-dropdown-icon ${
              lang === "ar"
                ? "news-dropdown-icon-left"
                : "news-dropdown-icon-right"
            }`}
          />
          <select
            aria-label="filter news"
            onChange={(event) => handleSelection(event.target.value)}
            className={`news-select font light`}
          >
            <option
              value={"1"}
              className={`${
                selected === "1" ? "font bold" : ""
              } p-1 news-select__option`}
            >
              {t("All")}
            </option>
            <option
              value={"2"}
              className={`${
                selected === "2" ? "font bold" : ""
              } p-1 news-select__option`}
            >
              {t("Banking News")}
            </option>
            <option
              value={"3"}
              className={`${
                selected === "3" ? "font bold" : ""
              } p-1 news-select__option`}
            >
              {t("General News")}
            </option>
          </select>
        </div>
      </Row>
      {isLoading ? (
        <Loader />
      ) : (
        news?.length > 0 &&
        news?.map((item: NewsItem, index: number) => {
          return <NewsRow news={item} key={index} />;
        })
      )}
      {newsPageCount > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <>
              <span className={`font bold pagination__litag`}>{t("Next")}</span>
              <img
                src={images.leftArrowforPages}
                className={lang === "en" ? "flippedIcon" : "paginate__arrows"}
              />
            </>
          }
          previousLabel={
            <>
              <img
                src={images.rightArrowforPages}
                className={lang === "en" ? "flippedIcon" : "paginate__arrows"}
              />
              <span className={`font bold pagination__litag`}>
                {t("Previous")}
              </span>
            </>
          }
          className={"paginate-line"}
          pageClassName={"font bold pagination__litag"}
          pageLinkClassName={"pagination-page__atag"}
          nextLinkClassName={"pagination__atag"}
          previousLinkClassName={"pagination__atag"}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={newsPageCount}
          activeClassName={"active-page"}
        />
      )}
    </section>
  );
};

export default LatestNews;
