import React from "react";
import CircleIcon from "./CircleIcon";
import { useTranslation } from "react-i18next";

const ServiceRow: React.FC<{ title: string; icon: string }> = ({
  title,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <div className="left-row">
      <CircleIcon icon={icon} />
      <div data-aos="fade-left" data-aos-duration="400" className="aos-init">
        <h3 className="font bold s24">{t(title)}</h3>
      </div>
    </div>
  );
};
export default ServiceRow;
