import { Tabs, Tab } from "react-bootstrap";
import TabContent from "./TabContent";
import React from "react";
import { useTranslation } from "react-i18next";

interface props {
  nano: boolean;
  faqs: {
    id: number;
    category: string;
    Q_As: {
      id: number;
      question: string;
      answer: string;
    }[];
  }[];
}
const FaqTabs: React.FC<props> = ({ faqs, nano }) => {
  const { t } = useTranslation();

  return (
    <>
      <Tabs
        defaultActiveKey={faqs[0]?.id}
        id="uncontrolled-tab-example"
        className="faq-tabs font bold"
      >
        {faqs?.map((faq: any) => (
          <Tab
            eventKey={faq.id}
            title={nano ? t(faq.category) : faq.category}
            key={faq.id}
          >
            <TabContent tabData={faq.Q_As} nano={nano} />
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default FaqTabs;
