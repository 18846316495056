import React, { useEffect, useState } from "react";
import DefaultMain from "layout";
import images from "assets/images";
import StoryModal from "components/StoryModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { API_getSuccessStories } from "store/actions/contentActions";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

const SuccessStories: React.FC = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const { successStories } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const onRender = async () => {
    setIsLoading(true);
    await dispatch(API_getSuccessStories());
    setIsLoading(false);
  };
  useEffect(() => {
    onRender();
  }, []);

  return (
    <>
      <Helmet>
        <title>Success Stories - amanmicrofinance.com</title>
      </Helmet>
      <DefaultMain>
        <section className="success-story__section">
          <div className="success-story__header">
            <h2 className="font text-white bold">
              {t("Aman success stories")}
            </h2>
            <p className="font text-white light mb-5">
              {t(
                "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector."
              )}
            </p>
          </div>
          {isLoading ? (
            <div className="text-center my-5">
              <Spinner animation="border" variant="info" />
            </div>
          ) : (
            <div className="success-stories__content">
              <div className="success-stories__content__items">
                {successStories?.map((successStory: any) => (
                  <div
                    className="success-stories__content__item mb-5"
                    key={successStory.id}
                  >
                    <StoryModal
                      videoUrl={successStory.url}
                      name={successStory.description}
                    >
                      <img
                        src={successStory.image}
                        alt="SuccessStory"
                        className="w-100 success__story__img"
                      />
                      <img
                        src={images.videoPlayIcon}
                        alt="video play"
                        className="video__play__icon"
                      />
                    </StoryModal>
                    <div className="success__story__content__title">
                      <p className="text-white font">
                        {successStory.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </section>
      </DefaultMain>
    </>
  );
};
export default SuccessStories;
