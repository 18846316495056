import { Image } from "react-bootstrap";

const HeroHeaderIcon: React.FC<{ path: string; icon: any }> = ({
  path,
  icon,
}) => {
  return (
    <a target={"_blank"} href={path}>
      <Image width="43" height="43" src={icon} className="m-1" />
    </a>
  );
};

export default HeroHeaderIcon;
