import constants from "../constants";

let initialState = {
  governments: [],
  amountOfLoansMf: [],
  amountOfLoansSme: [],
  amountOfLoanLv: [],
  faqs: [],
  annualReports: [],
  landingPage: {},
  careers: [],
  careersPageCount: 1,
  news: [],
  featuredNews: null,
  newsPageCount: 1,
  newsDetails: null,
  successStories: [],
  careerDetails: {},
  about: {},
  userProtectionGuide: null,
  policy: {},
  constants:{}
};
const contentReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case constants.SET_AMOUNT_OF_LOANS_SME:
      return {
        ...state,
        amountOfLoansSme: action.payload,
      };
    case constants.SET_AMOUNT_OF_LOANS_Lv:
      return {
        ...state,
        amountOfLoanLv: action.payload,
      };
    case constants.SET_GOVERNMENTS:
      return {
        ...state,
        governments: action.payload,
      };
    case constants.SET_AMOUNT_OF_LOANS:
      return {
        ...state,
        amountOfLoansMf: action.payload,
      };
    case constants.SET_FAQ:
      return {
        ...state,
        faqs: action.payload,
      };
    case constants.SET_ANNUAL_REPORTS:
      return {
        ...state,
        annualReports: action.payload,
      };
    case constants.SET_LANDING_PAGE:
      return {
        ...state,
        landingPage: action.payload,
      };
    case constants.SET_CAREERS:
      return {
        ...state,
        careers: action.payload,
      };
    case constants.SET_CAREERS_PAGE_COUNT:
      return {
        ...state,
        careersPageCount: action.payload,
      };
    case constants.SET_CAREER_DETAILS:
      return {
        ...state,
        careerDetails: action.payload,
      };
    case constants.SET_NEWS:
      return {
        ...state,
        news: action.payload,
      };
    case constants.SET_FEATURED_NEWS:
      return {
        ...state,
        featuredNews: action.payload,
      };
    case constants.SET_NEWS_DETAILS:
      return {
        ...state,
        newsDetails: action.payload,
      };
    case constants.SET_NEWS_PAGE_COUNT:
      return {
        ...state,
        newsPageCount: action.payload,
      };
    case constants.SET_SUCCESS_STORIES: {
      return {
        ...state,
        successStories: action.payload,
      };
    }
    case constants.SET_USER_PROTECTION_GUIDE: {
      return {
        ...state,
        userProtectionGuide: action.payload,
      };
    }
    case constants.SET_ABOUT: {
      return {
        ...state,
        about: action.payload,
      };
    }
    case constants.SET_POLICY: {
      return {
        ...state,
        policy: action.payload,
      };
    }
    case constants.SET_CONTENT_CONSTANT: {
      return {
        ...state,
        constants: action.payload,
      };
    }
    default:
      return state;
  }
};
export default contentReducer;
