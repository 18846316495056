import amanTeam from "./images/img_aman_team.jpg";
import activeClientImg from "./images/active-clients-number.png";
import activeClientIcon from "./images/active-clients-number-icon.svg";
import branchesIcon from "./images/stat-branches-icon.svg";
import branchesImg from "./images/stat-branches-background.png";
import shakingHandsImg from "./images/img_shaking_hands.svg";
import amanStore from "./images/img_aman_store.jpg";
import doubleArrow from "./images/icon_double_arrow.svg";
import nextArrowBtn from "./images/next-arrowBtn.svg";
import prevArrowBtn from "./images/prev-arrowBtn.svg";
import orangetrin from "./images/orangetrin.svg";
import phone from "./images/phone.svg";
import linkedin from "./images/icon-linkedin.svg";
import youtube from "./images/icon-youtube.svg";
import whatsapp from "./images/icon-whatsapp.svg";
import facebook from "./images/icon-facebook.svg";
import hamburger from "./images/hamburger.svg";
import circleIcon01 from "./images/circle-icon-01.png";
import circleIcon02 from "./images/circle-icon-02.png";
import circleIcon03 from "./images/circle-icon-03.png";
import circleIcon05 from "./images/circle-icon-05.png";
import circleIcon16 from "./images/circle-icon-16.png";
import circleIcon17 from "./images/circle-icon-17.png";
import circleIcon13 from "./images/circle-icon-13.png";
import circleIcon14 from "./images/circle-icon-14.png";
import circleIcon15 from "./images/circle-icon-15.png";
import circleIcon04 from "./images/circle-icon-04.png";
import circleIcon06 from "./images/circle-icon-06.png";
import circleIcon07 from "./images/circle-icon-07.png";
import circleIcon08 from "./images/circle-icon-08.png";
import circleIcon09 from "./images/circle-icon-09.png";
import amanReader from "./images/aman-reader.png";
import kiteCircle from "./images/kite-circle.png";
import orangeArrow from "./images/orange-arrow.svg";
import appAdvertiseGoogleStore from "./images/amanApp-advertise-google-store.svg";
import appAdvertiseAppleStore from "./images/amanApp-advertise-apple-store.svg";
import checkmark from "./images/checkmark-orange.svg";
import googlePlay from "./images/googleplay.svg";
import appStore from "./images/playstore.svg";
import amanLogo from "./images/transparent-aman-logo.png";
import triangle from "./images/triangle-icon-3x.png";
import SuccessStory from "./images/sucess-story-2.png";
import videoPlayIcon from "./images/Video play.svg";
import topNavIcon from "./images/topNav-icon.svg";
import contactusPhone from "./images/contactus-phone.svg";
import coin from "./images/aman-coin.png";
import contactUs from "./images/contuc-us.png";
import policyIcon1 from "./images/circle-icon-03.png";
import policyIcon2 from "./images/circle-icon-11.png";
import policyIcon3 from "./images/circle-icon-12.png";
import carousel0 from "./images/carousel-0.jpg";
import carousel1 from "./images/carousel-1.jpg";
import carousel2 from "./images/carousel-2.jpg";
import carousel3 from "./images/carousel-3.jpg";
import carousel4 from "./images/carousel-4.jpg";
import carousel5 from "./images/carousel-5.jpg";
import carousel6 from "./images/carousel-6.jpg";
import carousel7 from "./images/carousel-7.jpg";
import carousel8 from "./images/carousel-8.jpg";
import carousel9 from "./images/carousel-9.jpg";
import carousel10 from "./images/carousel-10.jpg";
import kiteArrow from "./images/icon-rounded-orange-arrow.svg";
import coin3x from "./images/coins-3x.png";
import effort from "./images/effort.svg";
import priceTag from "./images/price-tag.svg";
import reader3x from "./images/aman-reader-3x.png";
import kitePath from "./images/kite-path.svg";
import rightArrow from "./images/icon-arrow-right-white-trans.svg";
import leftArrow from "./images/icon-arrow-left-white-trans.svg";
import visualArrow from "./images/visual-arrows.svg";
import scCarousel1 from "./images/sc-carousel-1.jpg";
import scCarousel2 from "./images/sc-carousel-2.png";
import scCarousel3 from "./images//sc-carousel-3.png";
import scCarousel4 from "./images/sc-carousel-4.png";
import scCarousel5 from "./images/sc-carousel-5.jpg";
import scCarousel6 from "./images/sc-carousel-6.png";
import amanRayaLogo from "./images/logo-aman-with-raya.png";
import visualArrowGreen from "./images/visual-arrows-withgreen.svg";
import mapIcon from "./images/icon-map-white.svg";
import clientsIcon from "./images/icon-clients-white.svg";
import arrowTop from "./images/arrow-top.png";
import heroCarousel1 from "./images/hero-carousel-1.png";
import heroCarousel2 from "./images/hero-carousel-2.jpg";
import heroCarousel3 from "./images/hero-carousel-3.jpg";
import landingMap from "./images/landing_map.svg";
import leftArrowSmall from "./images/icon-arrow-left-white.svg";
import onlineMerchants from "./images/icon-aman-online-merchants.png";
import moneyIcon from "./images/icon-money.png";
import timeIcon from "./images/icon-time.png";
import artArrow from "./images/art-arrow.svg";
import conditionIcon from "./images/icon-conditions.svg";
import checkMark from "./images/icon-checkmark.svg";
import errorIcon from "./images/icon-error.svg";
import artArrowLevel2 from "./images/art-arrow-level2.svg";
import servicesMain from "./images/servicesMain.jpg";
import firstAmanAppAdvertise from "./images/aman-app-advertise.png";
import secondAmanAppAdvertise from "./images/aman-app-advertise2.png";
import installmentsCover from "./images/installments-cover.png";
import phoneIcon from "./images/phone-icon.svg";
import arrow from "./images/arrow.svg";
import payInstallment from "./images/payInstallment.png";
import noInstallment from "./images/no-installments.png";
import installmentPaid from "./images/installment-paid.png";
import rightArrowforPages from "./images/right-arrow.svg";
import leftArrowforPages from "./images/left-arrow.svg";
import collapseMinus from "./images/Collapse-.svg";
import collapsePlus from "./images/Collapse+.svg";
import clock from "./images/clock.svg";
import location from "./images/Location.svg";
import joinus from "./images/join-us.png";
import upload from "./images/Upload.png";
import emptyNews from "./images/empty-news.png";
import pdfFile from "./images/pdf-file.svg";
import careers from "./images/Careers.jpg";
import dropdown from "./images/dropdown.svg";
import editIcon from "./images/edit.svg";
import whatsappBlueIcon from "./images/blue-whatsapp.svg";
import twitterBlueIcon from "./images/blue-twitter.svg";
import facebookBlueIcon from "./images/blue-facebook.svg";
import linkedinBlueIcon from "./images/blue-linkedin.svg";
import nanoLending from "./images/nano-lending.png";
import nanoApp from "./images/nano-download.png";
import arrowRight from "./images/arrow-right.svg";
import calendar from "./images/calendar.svg";
import moneyTime from "./images/money-time.svg";
import money from "./images/money.svg";
import paymentMetthods from "./images/payment-method.svg";
import closeIcon from "./images/close-cyan@3x.png";
import islamicLoanIcon from "./images/islamic_loan_icon.png";
import successSVG from "./images/succes.svg";
import errorSVG from "./images/errorIcon.svg";

import icon_orange_bacnk_name from "./images/icon-orange-bank-name.svg";
import icon_orange_user_name from "./images/icon-orange-user-name.svg";
import icon_orange_location from './images/icon-orange-location.svg';
import icon_orange_account_number from './images/icon-orange-account-number.svg';
import icon_orange_international_account_number from './images/icon-orange-international-account-number.svg';
import icon_orange_swift from "./images/icon-orange-swift.svg";
import icon_black_back_arrow from './images/icon-black-back-arrow.svg';
import icon_recipt from './images/recipt.svg';
import icon_trash_bin from './images/icon-trash-bin.svg';
import icon_edit_pen from './images/icon-edit-pen.svg';
import visual_success from './images/success message illustration.png';

export default {
  closeIcon,
  orangetrin,
  phone,
  linkedin,
  youtube,
  whatsapp,
  facebook,
  hamburger,
  circleIcon01,
  amanTeam,
  circleIcon02,
  circleIcon03,
  circleIcon05,
  kiteCircle,
  googlePlay,
  appStore,
  triangle,
  amanLogo,
  orangeArrow,
  SuccessStory,
  videoPlayIcon,
  topNavIcon,
  contactusPhone,
  coin,
  contactUs,
  appAdvertiseGoogleStore,
  appAdvertiseAppleStore,
  checkmark,
  firstAmanAppAdvertise,
  secondAmanAppAdvertise,
  policyIcon1,
  policyIcon2,
  policyIcon3,
  carousel0,
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5,
  carousel6,
  carousel7,
  carousel8,
  carousel9,
  carousel10,
  kiteArrow,
  coin3x,
  effort,
  priceTag,
  reader3x,
  kitePath,
  leftArrowSmall,
  leftArrowforPages,
  rightArrow,
  rightArrowforPages,
  leftArrow,
  visualArrow,
  scCarousel1,
  scCarousel2,
  scCarousel3,
  scCarousel4,
  scCarousel5,
  scCarousel6,
  amanRayaLogo,
  visualArrowGreen,
  mapIcon,
  clientsIcon,
  arrowTop,
  heroCarousel1,
  heroCarousel2,
  heroCarousel3,
  landingMap,
  installmentsCover,
  phoneIcon,
  arrow,
  payInstallment,
  noInstallment,
  installmentPaid,
  artArrow,
  moneyIcon,
  timeIcon,
  onlineMerchants,
  conditionIcon,
  checkMark,
  errorIcon,
  artArrowLevel2,
  // play,
  servicesMain,
  circleIcon16,
  circleIcon17,
  circleIcon13,
  circleIcon14,
  circleIcon15,
  circleIcon04,
  amanReader,
  circleIcon06,
  circleIcon07,
  circleIcon08,
  circleIcon09,
  activeClientImg,
  branchesImg,
  activeClientIcon,
  branchesIcon,
  shakingHandsImg,
  amanStore,
  doubleArrow,
  nextArrowBtn,
  prevArrowBtn,
  collapseMinus,
  collapsePlus,
  clock,
  location,
  joinus,
  upload,
  emptyNews,
  pdfFile,
  careers,
  dropdown,
  editIcon,
  whatsappBlueIcon,
  twitterBlueIcon,
  facebookBlueIcon,
  linkedinBlueIcon,
  nanoLending,
  nanoApp,
  arrowRight,
  calendar,
  money,
  moneyTime,
  paymentMetthods,
  islamicLoanIcon,
  successSVG,
  errorSVG,
  icon_orange_bacnk_name,
  icon_orange_user_name,
  icon_orange_location,
  icon_orange_account_number,
  icon_orange_international_account_number,
  icon_orange_swift ,
  icon_black_back_arrow,
  icon_recipt,
  icon_trash_bin,
  icon_edit_pen,
  visual_success
};
