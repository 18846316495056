import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactLoading from "react-loading";

interface Props {
  isSending?: boolean;
  disabled?: boolean;
  children: any;
  onClick: (e: any) => void;
  ref?: any;
  className?: string;
}

const CommonOrangeButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <button
      disabled={props.disabled || props.isSending}
      ref={props.ref}
      onClick={props.onClick}
      className={`common-orange-button ${
        props.disabled ? "common-orange-button--disable" : ""
      } ${props.className ? props.className : ""}`}
    >
      {props.isSending ? (
        <>
          <p>{t("Sending")}</p>
          <ReactLoading
            className="loading-element"
            type="balls"
            color={"#fff"}
            height={20}
            width={20}
          />
        </>
      ) : (
        props.children
      )}
    </button>
  );
};

export default CommonOrangeButton;
