export default {
  SET_GOVERNMENTS: "setGovernments",
  SET_AMOUNT_OF_LOANS: "setAmountOfLoansMf",
  SET_AMOUNT_OF_LOANS_SME: "setAmountOfLoansSme",
  SET_AMOUNT_OF_LOANS_Lv: "SET_AMOUNT_OF_LOANS_Lv",
  SET_FAQ: "setFaq",
  SET_LANG: "setLang",
  SET_ANNUAL_REPORTS: "setAnualReports",
  SET_LANDING_PAGE: "setLandingPage",
  SET_CAREERS: "setCareers",
  SET_CAREERS_PAGE_COUNT: "setCareersPageCount",
  SET_CAREERS_COUNT: "setCareersCount",
  SET_CAREER_DETAILS: "setCareerDetails",
  SET_NEWS: "setNews",
  SET_FEATURED_NEWS: "setFeaturedNews",
  SET_NEWS_PAGE_COUNT: "setNewsPageCount",
  SET_WIDTH: "setWidth",
  SET_SUCCESS_STORIES: "SET_SUCCESS_STORIES",
  SET_USER_PROTECTION_GUIDE: "SET_USER_PROTECTION_GUIDE",
  SET_ABOUT: "setAbout",
  SET_NEWS_DETAILS: "setNewsDetails",
  SET_POLICY: "setPoilcy",
  SET_CONTENT_CONSTANT:"setContentConstant"
};
