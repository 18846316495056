import images from "assets/images";
import DefaultMain from "layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container } from "react-bootstrap";
import CircleIconsRow from "../faq/components/CircleIconsRow";
import FaqTabs from "pages/faq/components/FaqTabs";
import { faqs, imgs } from "../../content/nanoLending";
import { Helmet } from "react-helmet";

const NanoLendingInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Nano Lending - amanmicrofinance.com</title>
      </Helmet>
      <DefaultMain>
        <div className="nano-lending-wrapper">
          <section className="d-flex flex-wrap nano-hero-section">
            <div className="col-lg-6 nano-hero-section__right">
              <h1 className="text-white font bold">{t("Nano Lending")}</h1>
              <p className="text-white font light">
                {t(
                  "It is one of the rapid financing systems from Aman Company that is done in an easy and fast way, and inquiries are made and financing is received on the same day."
                )}
              </p>
              <div className="bg-text">
                <p>
                  {t("It is granted to individuals with a minimum of")}{" "}
                  <span className="bg-span">500</span>{" "}
                  {t("Egyptian pounds and a maximum of")}{" "}
                  <span className="bg-span">3000</span>{" "}
                  {t("Egyptian pounds per person")}
                </p>
              </div>
            </div>
            <div className="col-lg-5 nano-hero-section__left">
              <img src={images.nanoLending} />
            </div>
          </section>
          <section className="faq-container">
            <Col className="faq-box">
              <Container className="faq-tabs-container">
                <CircleIconsRow icons={imgs} />
                <FaqTabs nano={true} faqs={faqs} />
              </Container>
            </Col>
            <img src={images.artArrow} />
          </section>

          <section className="d-flex flex-wrap nano-app-download">
            <div className="col-lg-6 nano-app-download__right">
              <h1 className="text-white font bold">
                {t("Download the application now and apply for nano financing")}
              </h1>
              <p className="text-white font light">
                {t(
                  "Follow the necessary steps and go to the nearest branch to you from the network of Aman branches spread all over the Republic to receive the financing on the same day."
                )}
              </p>
              <h5 className="text-white font bold">
                {t("Call Us")} <span className="bg-span">19910</span>{" "}
              </h5>
              <div className="amanApp__advertise__appStore">
                <a
                  href="https://play.google.com/store/apps/details?id=com.amanmf&pli=1"
                  target="_blank"
                >
                  <img src={images.appAdvertiseGoogleStore} alt="App Store" />
                </a>
              </div>
            </div>
            <div className="col-lg-5 nano-app-download__left">
              <img src={images.nanoApp} alt="nano Lending steps" />
              <img src={images.arrowRight} alt="arrow" />
            </div>
          </section>
        </div>
      </DefaultMain>
    </>
  );
};
export default NanoLendingInfo;
