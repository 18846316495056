import images from "../assets/images";

export const imgs = [
  {
    id: 1,
    src: images.circleIcon02,
  },
  {
    id: 2,
    src: images.circleIcon03,
  },
  {
    id: 3,
    src: images.circleIcon04,
  },
  {
    id: 4,
    src: images.circleIcon01,
  },
];
