import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EmployeeType } from "types";
import { RootState } from "store/configStore";
import { Spinner } from "react-bootstrap";

interface IProps {
  loading: boolean;
}

const Section6: React.FC<IProps> = ({ loading }) => {
  const { t } = useTranslation();
  const { about } = useSelector((state: RootState) => state.contentReducer);

  return (
    <section
      className="aboutAman__teamMembers__section py-5"
      id={"teamMembers"}
    >
      <h2 className="font bold text-white mx-4">{t("Management Team")}</h2>
      <div className="aboutAman__teamMembers__items col-11 d-flex py-5">
        {loading ? (
          <div className="spinner">
            <Spinner animation="border" variant="info" />
          </div>
        ) : (
          about?.employees?.map((employee: EmployeeType, index: number) => {
            return (
              <div
                className="aboutAman__teamMember__item col-lg-4 col-xl-3 col-xs-6"
                key={index}
              >
                <div className="aboutAman__teamMember__item__wrapper">
                  <img
                    src={employee.image}
                    alt="Aman TeamMember"
                    className="w-100"
                  />
                  <div className="aboutAman__teamMember__item__content w-100">
                    <h4 className="font bold text-white">{employee.name}</h4>
                    <p className="font light text-white">
                      {employee.job_title}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </section>
  );
};
export default Section6;
