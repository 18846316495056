import images from "assets/images";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store/configStore";
import CommonModal from "components/CommonModal";
import CommonOrangeButton from "components/Button";
import { API_PostContactUsReq } from "store/actions/contentActions";

const EgyptionNumbersRegex = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;

const typesOfForm = ["complain", "suggestion"];
const ContactusForm = () => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);
  const [isSentSucc, setIsSentSucc] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [fullName, setFullName] = useState("");
  const [messageType, setMessageType] = useState(0);

  const [isFullNameValid, setIsFullNameValid] = useState<boolean | undefined>(
    undefined
  );

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<
    boolean | undefined
  >(undefined);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("0");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [message, setMessage] = useState("");

  const { governments } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const dispatch = useDispatch();
  const clearForm = () => {
    setFullName("");
    setAddress("");
    setPhoneNumber("");
    setCity("0");
    setMessage("");
    setDateOfBirth("");
  };
  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);
    const res: any = await dispatch(
      API_PostContactUsReq({
        full_name: fullName.trim(),
        phone_number: phoneNumber,
        address: address.trim(),
        government: Number(city),
        dob: dateOfBirth,
        message: message.trim(),
        type: typesOfForm[messageType - 1],
      })
    );
    setIsSending(false);
    setIsSentSucc(res.status == 200);
    setIsModalOpen(true);
    clearForm();
  };
  useEffect(() => {
    if (fullName.length > 0)
      setIsFullNameValid(
        /^[\u0621-\u064A| +]+$/.test(fullName) ||
          /^[a-zA-Z| +]+$/.test(fullName)
      );
    else setIsFullNameValid(undefined);
  }, [fullName]);

  useEffect(() => {
    if (phoneNumber.length > 0)
      setIsPhoneNumberValid(EgyptionNumbersRegex.test(phoneNumber));
    else setIsPhoneNumberValid(undefined);
  }, [phoneNumber]);
  return (
    <>
      <CommonModal
        isSuccess={isSentSucc}
        successText="Your Message Has been sent successfully"
        errorText="An Error Occured Please try again"
        show={isModalOpen}
        handleClose={setIsModalOpen}
      />

      <Form onSubmit={sendMessage}>
        <Form.Group
          as={Col}
          controlId="formGridMessage"
          className="mb-3 form-message-type font light"
        >
          <Form.Select
            onChange={(e: any) => {
              setMessageType(e.target.value);
            }}
            value={messageType}
            defaultValue={0}
            className="contact-us__form__select"
          >
            <option value={0}>{t("Choose the type*")}</option>
            <option value={1}>{t("To Complain")}</option>
            <option value={2}>{t("For Suggestions")}</option>
          </Form.Select>
        </Form.Group>
        <Row className="mb-3 contact-us__form__row-1 ">
          <Form.Group as={Col} controlId="formGridName">
            <Form.Label className="font bold">{t("Full name")}*</Form.Label>
            <Form.Control
              maxLength={100}
              className={`${
                isFullNameValid === false ? "required-border" : ""
              }`}
              type="text"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
              required
            />
            {isFullNameValid === false && (
              <div className="error-state mb-3">
                <img src={images.errorIcon} />
                <p style={{ paddingTop: 0 }}>
                  {t("Please enter valid full name")}
                </p>
              </div>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label className="font bold">
              {t("Telephone Number")}*
            </Form.Label>
            <Form.Control
              maxLength={11}
              type="phone"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
            {isPhoneNumberValid === false && (
              <div className="error-state mb-3">
                <img src={images.errorIcon} />
                <p style={{ paddingTop: 0 }}>
                  {t("Please enter valid phone number")}
                </p>
              </div>
            )}
          </Form.Group>
        </Row>
        <Form.Group className="mb-3" controlId="formGridAddress">
          <Form.Label className="font bold">{t("The Address")}</Form.Label>
          <Form.Control
            maxLength={200}
            type="text"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Form.Group>
        <Row className="mb-3 contact-us__form__row g-1 row">
          <Form.Group as={Col} controlId="formGridCity" className="font light">
            <Form.Label className="font bold">{t("City*")}</Form.Label>
            <Form.Select
              className="contact-us__form__select"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            >
              <option value={"0"}>{t("Choose a Governorate..")}</option>
              {governments.map((gov: any) => (
                <option key={gov.id} value={gov.id}>
                  {gov.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group
            className="formGridDateOfBirth"
            as={Col}
            controlId="formGridDateOfBirth"
          >
            <Form.Label className="font bold">{t("Date of Birth")}</Form.Label>
            <Form.Control
              type="date"
              max={"2017-12-30"}
              value={dateOfBirth}
              onChange={(e) => {
                setDateOfBirth(e.target.value);
              }}
            />
          </Form.Group>
        </Row>
        <Form.Group
          className="mb-3 contact-us__form__input"
          as={Col}
          controlId="formGridMessageArea"
        >
          <Form.Label className="font bold">
            {" "}
            {t("Message Content")}*
          </Form.Label>
          <Form.Control
            maxLength={500}
            as="textarea"
            rows={7}
            className="contact-us__form__textarea"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            required
          />
        </Form.Group>
        <CommonOrangeButton
          disabled={
            fullName.trim().length == 0 ||
            phoneNumber.length == 0 ||
            message.trim().length == 0 ||
            !isFullNameValid ||
            !isPhoneNumberValid ||
            messageType == 0 ||
            city == "0" ||
            isSending
          }
          onClick={sendMessage}
          className="font bold contact-us__btn"
        >
          {t("Send a message")}
        </CommonOrangeButton>
      </Form>
    </>
  );
};

export default ContactusForm;
