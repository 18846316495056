import images from "../../../assets/images";
import { useTranslation } from "react-i18next";
const Section3: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="aboutAman__shakingHand__section mt-5" id={"aboutAman"}>
      <div className="d-flex">
        <div className="aboutAman__shakingHand__img col-lg-4"></div>
        <div className="aboutAman__shakingHand__content col-lg-7">
          <h3 className="font bold text-white mb-4">{t("About aman")}</h3>
          <h2 className="font bold text-white mb-5">
            {t("We aim to be the leader in microfinance")}
          </h2>
          <p className="font light text-white fs-5">
            {t(
              "Aman Microfinance Company relies on technical and technological expertise, credibility, trustworthiness and market leadership of the parent company Raya."
            )}
          </p>
          <p className="font light text-white fs-5 mb-5">
            {t(
              "As well as on the expertise of the Aman Financial Services Company, which is owned by the “Aman” company for electronic payment services, which has tremendous experience in the Egyptian market, especially in the field of providing electronic payment solutions. She also established a company (Fawry) for electronic payment services"
            )}
          </p>
          <a
            target={"_blank"}
            href="https://rayacorp.com/"
            className="btn font bold rounded-pill text-white"
          >
            {t("More about Raya Company")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Section3;
