import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import DefaultMain from "layout";
import Section1 from "./sections/section1";
import Section2 from "./sections/section2";
import Section3 from "./sections/section3";
import Section4 from "./sections/section4";
import Section5 from "./sections/section5";
import Section6 from "./sections/section6";
import Section7 from "./sections/section7";
import { API_GetLandingPage } from "store/actions/contentActions";
import { Helmet } from "react-helmet";

const Landing: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onRender = async () => {
    setLoading(true);
    await dispatch(API_GetLandingPage());
    setLoading(false);
  };

  useEffect(() => {
    onRender();
  }, []);

  return (
    <>
      <Helmet>
        <title>Home - amanmicrofinance.com</title>
      </Helmet>

      <DefaultMain>
        <Section1 loading={loading} />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 loading={loading} />
        <Section6 loading={loading} />
        <Section7 />
      </DefaultMain>
    </>
  );
};
export default Landing;
