import React, { useEffect, useState } from "react";
import DefaultMain from "layout";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import images from "assets/images";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { API_GetCareers } from "store/actions/contentActions";
import JobsAccordion from "./components/JobAccordion";
import ReactPaginate from "react-paginate";
import Loader from "components/Loader";
import { Helmet } from "react-helmet";

const Careers: React.FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const { careers } = useSelector((state: RootState) => state.contentReducer);
  const { careersPageCount } = useSelector(
    (state: RootState) => state.contentReducer
  );

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = (event: any) => {
    const pageNumber = event.selected + 1;
    if (pageNumber <= careersPageCount) {
      setCurrentPage(pageNumber);
    }
  };

  const onRender = async () => {
    setIsLoading(true);
    await dispatch(API_GetCareers(currentPage));
    setIsLoading(false);
  };

  useEffect(() => {
    onRender();
  }, [currentPage]);

  return (
    <>
      <Helmet>
        <title>Careers - amanmicrofinance.com</title>
      </Helmet>

      <DefaultMain>
        <Row className="careers-banner__section">
          <Col xs={12} lg={8} className="careers-header__header">
            <h2 className="font text-white bold">{t("Join Aman Team")}</h2>
            <p className="font text-white light ">
              {t("Be one of Aman microfinance team now")}
            </p>
            <Link to="/careers/join-us" className="amnmf-btn-filled m-0">
              {t("Apply now")}
            </Link>
          </Col>
          <Col xs={12} lg={4} className={"banner-image-container p-0"}>
            <img
              src={images.amanStore}
              className={"w-100 h-100 banner-image"}
            />
          </Col>
        </Row>

        <section className="careers-main__section">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <h2 className="font bold careers-main__header">
                {t("Available Jobs")} ({careers?.length})
              </h2>
              <JobsAccordion data={careers} />
            </>
          )}
          {careers?.length > 5 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <>
                  <span className={`font bold pagination__litag`}>
                    {t("Next")}
                  </span>
                  <img
                    src={images.leftArrowforPages}
                    className={
                      lang === "en" ? "flippedIcon" : "paginate__arrows"
                    }
                  />
                </>
              }
              previousLabel={
                <>
                  <img
                    src={images.rightArrowforPages}
                    className={
                      lang === "en" ? "flippedIcon" : "paginate__arrows"
                    }
                  />
                  <span className={`font bold pagination__litag`}>
                    {t("Previous")}
                  </span>
                </>
              }
              className={"paginate-line"}
              pageClassName={"font bold pagination__litag"}
              pageLinkClassName={
                "pagination-page__atagUpload a pdf file with size less than 50MB"
              }
              nextLinkClassName={"pagination__atag"}
              previousLinkClassName={"pagination__atag"}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={careersPageCount}
              activeClassName={"active-page"}
            />
          )}
        </section>
      </DefaultMain>
    </>
  );
};
export default Careers;
