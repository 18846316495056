import React from "react";
import images from "../assets/images";
const FinancialGrowShapeAnimated: React.FC = () => {
  return (
    <div className="financial__grow-shape__image">
      <img src={images.contactUs} alt="financial grow img" />
      <img src={images.coin} alt="coin img" />
    </div>
  );
};
export default FinancialGrowShapeAnimated;
