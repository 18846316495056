import React from "react";
import { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams, useParams } from "react-router-dom";

import images from "../../../assets/images";

const InstallmentPaid: React.FC = (props) => {
  const { t } = useTranslation();

  // const userNumber = "01112249433";
  // const trxId = "1320012";
  const [params] = useSearchParams();
  const phoneNumber = params.get("source_data.pan");
  const trxId = params.get("id");

  return (
    <section className="installments-section">
      <div className="installments-box">
        <div className="box-content">
          <div className="content-header">
            <h4 className="font bold">{t("Aman Finance")}</h4>
            <h5 className="font bold">{t("Installment Payment")}</h5>
          </div>
          <div className="content-main">
            <div className="installment-paid">
              <div className="trx-title">
                <Image src={images.installmentPaid} alt="" />
                <h5 className="font bold">{t("Installment paid successfully")}</h5>
              </div>
              <div className="receipt-details">
                <div className="receipt-id">
                  <h6 className="font bold">{t("Reciept ID")}</h6>
                  <p className="font light">{phoneNumber}</p>
                </div>
                <div className="trx-id">
                  <h6 className="font bold">{t("Transaction ID")}</h6>
                  <p className="font bold">{trxId}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-img">
          <Image src={images.installmentsCover} alt="" />
        </div>
      </div>
    </section>
  );
};

export default InstallmentPaid;
