import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "store/configStore";
import { rawHtmlToReact } from "../../../helper/HtmlToReact";

const Section1: React.FC = (props) => {
  const { policy } = useSelector((state: RootState) => state.contentReducer);

  return (
    <section className="privacy__section py-5 bg-white">
      {rawHtmlToReact(policy?.policy)}
    </section>
  );
};

export default Section1;
