import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import images from "assets/images";
import { RootState } from "store/configStore";
import HeroHeader from "../components/HeroHeader";
import HeroStatBox from "../components/HeroStatBox";
import HeroCarousel from "../components/HeroCarousel";

interface IProps {
  loading: boolean;
}

interface IStats {
  text1: string;
  text2: string;
  type: string;
  icon: any;
  bgClass: string;
}

const Section1: React.FC<IProps> = ({ loading }) => {
  const { landingPage } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const { constants } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const [clientStats, setClientStats] = useState<IStats>({
    text1: "",
    text2: "Active clients",
    icon: images.clientsIcon,
    type: "clients",
    bgClass: "--dark",
  });
  const [branchesStats, setBranchesStats] = useState<IStats>({
    text1: "",
    text2: "Branches all over Egypt",
    icon: images.mapIcon,
    type: "branches",
    bgClass: "--light",
  });
  const [phoneStats, setPhoneStats] = useState<IStats>({
    text1: "Contact us",
    text2: "",
    icon: images.phone,
    type: "phone",
    bgClass: "--orange",
  });

  useEffect(() => {
    setClientStats({
      text1: constants?.num_clients,
      text2: "Active clients",
      icon: images.clientsIcon,
      type: "clients",
      bgClass: "--dark",
    });
    setBranchesStats({
      text1: constants?.num_branches,
      text2: "Branches all over Egypt",
      icon: images.mapIcon,
      type: "branches",
      bgClass: "--light",
    });
    setPhoneStats({
      text1: "Contact us",
      text2: constants?.phone,
      icon: images.phone,
      type: "phone",
      bgClass: "--orange",
    });
  }, [landingPage]);

  return (
    <section className="row m-0 title-with-slider">
      <div className="col-xl-4 area-title">
        <HeroHeader />
        <div className="row area-title__stats">
          <Image
            src={images.arrowTop}
            alt=""
            className="floating-arrow"
            width={200}
            height={100}
          />
          <HeroStatBox data={clientStats} />
          <HeroStatBox data={branchesStats} />
          <HeroStatBox data={phoneStats} />
        </div>
      </div>
      <div className="col-xl-8 area-slider p-0">
        <Image
          src={images.visualArrowGreen}
          className="area-title__arrow-visual"
        />
        <HeroCarousel loading={loading} />
      </div>
    </section>
  );
};

export default Section1;
