import { Loader } from "@googlemaps/js-api-loader";
import { Government, Branch } from "types";

let map: google.maps.Map;
const markersArr: google.maps.Marker[] = [];

const loader = new Loader({
  apiKey: "--",
  version: "weekly",
});

const createInfoWindowTemplate = (branch: Branch) => {
  return `<div class='content'>
      <h3 class='font bold'>${branch.name}</h3>
      <p class='font light'>${branch.address}</p>
      <a class='font light' target='_blank' href='https://www.google.com/maps/?q=${branch.latitude},${branch.longitude}' >إفتح الخريطة</a>
    </div>`;
};

const createMarker = (branch: Branch) => {
  const { latitude: lat, longitude: lng } = branch;
  const marker = new google.maps.Marker({
    position: { lat, lng },
    map,
    title: branch.name,
    optimized: false,
  });
  return marker;
};

const placeAllMarkersOnMap = (governments: Government[]) => {
  let openedInfowidow = new google.maps.InfoWindow({});

  governments.forEach((gov: Government) => {
    gov.branches.forEach((branch: Branch) => {
      const infowindow = new google.maps.InfoWindow({
        content: createInfoWindowTemplate(branch),
      });

      const marker = createMarker(branch);

      markersArr.push(marker);

      marker.addListener("click", () => {
        openedInfowidow.close();
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: true,
        });
        openedInfowidow = infowindow;
      });
    });
  });
};

export const removeAllMarkers = () => {
  markersArr.forEach((m: google.maps.Marker) => m.setMap(null));
};

export const placeMarkerOnMap = (branch: Branch) => {
  const currMarker: google.maps.Marker = markersArr.filter(
    (m: google.maps.Marker) => m.getTitle() === branch.name
  )[0];

  const infowindow = new google.maps.InfoWindow({
    content: createInfoWindowTemplate(branch),
  });

  currMarker.setMap(map);
  infowindow.open({
    anchor: currMarker,
    map,
    shouldFocus: true,
  });
};

export const initMap = (governments: Government[]) => {
  loader.load().then(() => {
    map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: { lat: 29.624014, lng: 31.2508493 },
      zoom: 7,
    });
    placeAllMarkersOnMap(governments);
  });
};
