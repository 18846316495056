import images from "../assets/images";


export const servicesContent = {
  header1: "Financing micro-entrepreneurs with easy terms and procedures",
  sub1: "In accordance with the provisions of Law 141 of 2014 and the rules and standards issued by the Financial Supervisory Authority to regulate microfinance activity",
  sub2: "Funding value",
  btnTxt: "Read more about financing terms and requirements",
};

export const servicesItems = [
  {
    title: "Aman Finance",
    details:
      "Quick procedures for disbursing funding through the use of modern technology in disbursement.",
    itemsList: [
      {
        txt: "Financing all existing income-generating activities",
        img: images.circleIcon01,
      },
      { txt: "Apply for free financing", img: images.circleIcon02 },
      {
        txt: "Financing is tailored to suit the client's business volume",
        img: images.circleIcon03,
      },
      // {
      //   txt: "In the event of the customer's death during the validity period of the financing, their families, the “legitimate heirs” are exempted from paying the financing due to them, and this financing is paid on their behalf regardless of the remaining amount.",
      //   img: images.circleIcon16,
      // },
      // {
      //   txt: "Obtaining a 5% discount on the total purchases of Aman for Microfinance from all “Aman Installment Stores” for sale, numbering (260) stores, spread across 18 governorates of the Republic (whether these purchases are in cash or in installments)",
      //   img: images.circleIcon17,
      // },
    ],
  },
  {
    title: "Types of Activities Aman Funds",
    details:
      "Quick procedures for disbursing funding through the use of modern technology in disbursement",
    itemsList: [
      {
        txt: "Commercial",
        img: images.circleIcon13,
      },
      { txt: "Industrial", img: images.circleIcon14 },
      {
        txt: "Service",
        img: images.circleIcon15,
      },
    ],
  },
];



export const sec4Content = {
  title: "Financing Term",
  details:
    "In accordance with the provisions of Law 141 of 2014 and the rules and standards issued by the Financial Supervisory Authority to regulate microfinance activity",
  itemsList: [
    {
      txt: "The duration of the financing ranges from 4 months to 24 months, depending on the amount and purpose of the financing and the nature of the target client's activity.",
      img: images.circleIcon05,
      circleContent: ["4", "Months"],
    },
    {
      txt: "",
      img: "",
      circleContent: ["24", "Month"],
    },
  ],
  itemsClasses: ["s75", "s31"],
};

export const sec5Content = {
  title: "Paying the financing",
  details:
    "The payment shall be in equal installments, to be paid monthly, starting one month after the date of disbursement of the financing",
  itemsList: [
    {
      content: images.amanReader,
    },
    {
      content:
        "The possibility of payment through a network of Aman branches, electronic payment methods and in the nearest place to you.",
    },
  ],
};

export const singleRow = {
  title: "The foundations of our interest in our customers",
  btnTxt: "Guidelines for obtaining financing from Aman",
};

export const circlesRow = [
  {
    txt: "Simple procedures for financing without relying on any traditional guarantees",
    img: images.circleIcon06,
  },
  {
    txt: "Financing is designed to suit your activity, no matter how small",
    img: images.circleIcon07,
  },
  {
    txt: "Free life insurance for customers for the duration of the financing period",
    img: images.circleIcon08,
  },
  {
    txt: "Apply the principles of customer protection",
    img: images.circleIcon09,
  },
];
