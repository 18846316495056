export const en = {
  translation: {
    "Aman microfinance ": "Aman microfinance",
    "Aman clients": "Aman clients",
    "Financing Terms": "Financing Terms",
    "Financing term and payment": "Financing term and payment",
    "Advantages and costs of financing": "Advantages and costs of financing",
    Administration: "Administration",
    "societal role": "societal role",
    "Aman offices": "Branches",
    "Aman Finance": "Aman Finance",
    About: "About",
    "financing programs": "financing programs",
    "questions and answers": "FAQ",
    "A set of frequently asked questions about Aman financing, terms and conditions, and how to pay":
      "A set of frequently asked questions about Aman financing, terms and conditions, and how to pay",
    "annual report": "Annual report",
    "online merchants": "online merchants",
    "Small and medium finance": "Small and medium finance",
    "Guide to protect customers in the non-banking sector":
      "Guide to protect customers in the non-banking sector",
    news: "news",
    Jobs: "Jobs",
    "Aman App": "Aman App",
    "Privacy Policy": "Privacy Policy",
    "Financial Supervisory Authority License No. 4":
      "Financial Supervisory Authority License No. 4",
    "Request for Funding": "Request for Funding",
    "Pay your installments": "Pay your installments",
    lang: "AR",
    "The latest company to provide microfinance services in Egypt with the highest technological systems and programs":
      "The latest company to provide microfinance services in Egypt with the highest technological systems and programs",
    "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector.":
      "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector.",
    Vision: "Vision",
    "About aman": "About aman",
    "Active client": "Active client",
    "Branch all over Egypt": "Branch all over Egypt",
    "We aim to be the leader in microfinance":
      "We aim to be the leader in microfinance",
    "Aman Microfinance Company relies on technical and technological expertise, credibility, trustworthiness and market leadership of the parent company Raya.":
      "Aman Microfinance Company relies on technical and technological expertise, credibility, trustworthiness and market leadership of the parent company Raya.",
    "As well as on the expertise of the Aman Financial Services Company, which is owned by the “Aman” company for electronic payment services, which has tremendous experience in the Egyptian market, especially in the field of providing electronic payment solutions. She also established a company (Fawry) for electronic payment services":
      "As well as on the expertise of the Aman Financial Services Company, which is owned by the “Aman” company for electronic payment services, which has tremendous experience in the Egyptian market, especially in the field of providing electronic payment solutions. She also established a company (Fawry) for electronic payment services",
    "More about Raya Company": "More about Raya Company",
    "Enhancing and spreading financial inclusion and leadership in providing financing to business owners and micro-enterprises with appropriate speed and flexibility, and using the best smart technological means":
      "Enhancing and spreading financial inclusion and leadership in providing financing to business owners and micro-enterprises with appropriate speed and flexibility, and using the best smart technological means",
    "To be (Aman) the market leader in using the best programs and smart technological solutions to provide all non-banking financial services and products to the broad sector of craftsmen and micro-business owners, with speed and flexibility appropriate to the nature of dealing with clients of this sector, and through branches spread in all governorates of the Republic .":
      "To be (Aman) the market leader in using the best programs and smart technological solutions to provide all non-banking financial services and products to the broad sector of craftsmen and micro-business owners, with speed and flexibility appropriate to the nature of dealing with clients of this sector, and through branches spread in all governorates of the Republic .",
    "Aman Microfinance Company aims to provide financing to business owners and small and micro enterprises on easy terms and procedures commensurate with the nature of the activities practiced by these groups within the different regions and governorates in which they practice their activities and in accordance with the Small and Micro Enterprises Regulations Law and the rules and standards of practice issued by the Authority General Financial Supervisory Authority to regulate the activity.":
      "Aman Microfinance Company aims to provide financing to business owners and small and micro enterprises on easy terms and procedures commensurate with the nature of the activities practiced by these groups within the different regions and governorates in which they practice their activities and in accordance with the Small and Micro Enterprises Regulations Law and the rules and standards of practice issued by the Authority General Financial Supervisory Authority to regulate the activity.",
    "The message": "The message",
    Objectives: "Objectives",
    "Management Team": "Management Team",
    "Please leave your data and we will reply as soon as possible":
      "Please leave your data and we will reply as soon as possible",
    "Aman appreciates your valuable feedback, suggestions, or any inquiries":
      "Aman appreciates your valuable feedback, suggestions, or any inquiries",
    "100 New Branches": "100 New Branches",
    "Financial Inclusion": "Financial Inclusion",
    "Aman Microfinance Company was established in 2018 as one of the arms of Aman Financial Services Company to provide easier financial services for economically active groups that are not available to them with financial services appropriate to the nature of these groups":
      "Aman Microfinance Company was established in 2018 as one of the arms of Aman Financial Services Company to provide easier financial services for economically active groups that are not available to them with financial services appropriate to the nature of these groups",
    Foundation: "Foundation",
    ActivityType: "Activity type",
    // "Do you have official documents for the activity? (commercial register / tax card)": ""
  },
};
