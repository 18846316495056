import React from "react";
import { Row, Col } from "react-bootstrap";
import images from "../../../assets/images";
import { servicesContent, singleRow } from "../../../content/services";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const SingleRow: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <Row className="single-row bright-blue">
      <Col
        data-aos="fade-left"
        data-aos-duration="400"
        className="right-section2 aos-init"
      >
        <h2 className="font bold s23 w100 orangeBg">{t(singleRow.title)}</h2>
      </Col>
      <Col className="left-section">
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="left-text-with-icon aos-init"
        >
          <NavLink to={"/loan-request-mf"} className="font light s23 w100">
            {t(singleRow.btnTxt)}
            <img src={images.orangeArrow} width="35" height="35" alt="" />
          </NavLink>
          {/* <a href="#" className="font light s23 w100">
            {t(singleRow.btnTxt)}
            <img src={images.orangeArrow} width="35" height="35" alt="" />
          </a> */}
        </div>
      </Col>
    </Row>
  );
};

export default SingleRow;
