import images from "../assets/images";

export const heroHeaderIcons = [
  {
    path: "https://www.facebook.com/AmanforMicrofinance",
    icon: images.facebook,
  },
  {
    path: "https://www.youtube.com/channel/UC2aKVBF0VNpLMbUv5TkkIcQ",
    icon: images.youtube,
  },
  {
    path: "https://web.whatsapp.com/send/?phone=20219910&text=hello&type=phone_number&app_absent=0",
    icon: images.whatsapp,
  },
  {
    path: "https://www.linkedin.com/company/aman-for-micro-finance",
    icon: images.linkedin,
  },
];

export const startProjectContent = {
  title: "Start and grow your business",
  details:
    "The amount of your financing will be determined according to your project and your evaluation",
};

export const appAdv = {
  title: "Aman Finance Advantages",
  items: [
    {
      text: "Pay at Aman branches and at any electronic payment machine",
      img: images.reader3x,
    },
    // {
    //   text: "Discount up to 50% with a aman receipt in selected radiology and analysis centers",
    //   img: images.priceTag,
    // },
    {
      text: "Quick disbursement procedures",
      img: images.effort,
    },
    {
      text: "Financing all existing income-generating activities",
      img: images.coin3x,
    },
  ],
};

export const branchesContent = {
  title: "Aman offices",
  details: "Aman offices covering all governorates of Egypt, a total of ",
  detailsContinue: " branches, and we aim to be the closest to everyone",
  btnTxt: "Browse the map",
};

export const successStoriesContent = {
  title: "Aman success stories",
  subText:
    "Aman Microfinance Company has innovative solutions, always focusing on the client and understanding his needs, and is fully aware of the needs of the small and micro enterprises sector",
  btnText: "View all",
};

export const mobileAppContent = [
  "Submit and track your financing request",
  "Follow the installment dates",
  "Contact customer service",
  "Browse Aman Store and order any product that serves your project",
];
