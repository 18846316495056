import { Image } from "react-bootstrap";
import images from "assets/images";

interface props {
  icons: {
    id: number;
    src: string;
  }[];
}
const CircleIconsRow: React.FC<props> = ({ icons }) => {
  return (
    <div className="shift-down">
      <div className="circleIcons-row">
        {icons.map((item, index) => (
          <div className="circle-icon" key={`icon-${index}`}>
            <Image
              className="kite-img"
              src={images.kiteCircle}
              width={110}
              height={110}
              alt="kite image"
            />
            <Image
              className="icon-img"
              width={60}
              height={60}
              alt="50x50"
              src={item.src}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CircleIconsRow;
