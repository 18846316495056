import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import DefaultMain from "layout";
import Loader from "components/Loader";
import NewsDetailsMain from "./sections/NewsDetailsMain";
import NewsDetailsFooter from "./sections/NewsDetailsFooter";
import { API_GetNews, API_GetNewsDetails } from "store/actions/contentActions";

const NewsDetails: React.FC = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const getNews = async () => {
    if (params.newsId == "featured-news") {
      await dispatch(API_GetNews(1, true));
    } else {
      await dispatch(API_GetNewsDetails(Number(params.newsId)));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getNews();
    setIsLoading(false);
  }, [params.newsId]);

  return (
    <DefaultMain>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <NewsDetailsMain />
          {!(params.newsId == "featured-news") && <NewsDetailsFooter />}
        </>
      )}
    </DefaultMain>
  );
};
export default NewsDetails;
