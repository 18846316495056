import { Image } from "react-bootstrap";
import { LandingCarouselItem } from "../../../types/index";

const CarouselItem: React.FC<{ data: LandingCarouselItem }> = ({ data }) => {
  return (
    <div className="area-slider__slider__item">
      <Image src={data.image} alt="sc slider" />

      <div className="area-slider__slider__item__label">
        <div className="text">
          <h4 className="f-l font bold pb-2">{data.title}</h4>
          <p className="f-sm font light pb-3">{data.description}</p>
          <a href={data.url} className="amnmf-btn-outlined m-0  font bold f-sm">
            {data.button_text}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
