import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InstallmentsVerify: React.FC = (props) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState("03MEAOP");
  const userMobile = "01112249433";
  const inputChangeHandler = (ev: any) => {
    setVerificationCode(ev.target.value);
  };

  return (
    <>
      <h6 className="font bold main-text">
        {t("Please enter verification code")}
      </h6>
      <p className="font light sub-text">
        {t("A text message contains verification code was sent")}
        {userMobile}
      </p>

      <input
        type="text"
        value={verificationCode}
        className="verification-code font bold"
        onChange={inputChangeHandler}
      />
      <Button className="amnmf-btn --filled">
        {t("Check verification code")}
      </Button>
      <p className="resend-verification font light">
        {t("Didn't recieve the message?")}
        <span className="font bold">{t("Send again")}</span>
      </p>
    </>
  );
};

export default InstallmentsVerify;
