import React from "react";
import { useTranslation } from "react-i18next";
const ContentBox: React.FC<{ index: any; title: string }> = ({
  index,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <div className="content-box">
      <p className="font bold">{index}</p>
      <p className="font bold">{t(title)}</p>
    </div>
  );
};

export default ContentBox;
