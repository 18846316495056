import axios from "axios";
import React from "react";
import { baseUrl } from "netwrok/baseUrl";
import endPoints from "netwrok/endPoints";
import constants from "../constants";

import { CareersData, ContactUsData } from "types/backend.modal";
import { NewsItem } from "types";
import { RootState } from "store/configStore";

const setGovernments = (governments: any[]) => {
  return {
    type: constants.SET_GOVERNMENTS,
    payload: governments,
  };
};

const setUserProtectionGuide = (urlObject: any) => {
  return {
    type: constants.SET_USER_PROTECTION_GUIDE,
    payload: urlObject,
  };
};
const setSuccessStories = (successStories: any[]) => {
  return {
    type: constants.SET_SUCCESS_STORIES,
    payload: successStories,
  };
};

export const setAmountOfLoansMf = (amounOfLoans: any[]) => {
  return {
    type: constants.SET_AMOUNT_OF_LOANS,
    payload: amounOfLoans,
  };
};
export const setAmountOfLoansSme = (amounOfLoans: any[]) => {
  return {
    type: constants.SET_AMOUNT_OF_LOANS_SME,
    payload: amounOfLoans,
  };
};
export const setAmountOfLoansLv = (amounOfLoans: any[]) => {
  return {
    type: constants.SET_AMOUNT_OF_LOANS_Lv,
    payload: amounOfLoans,
  };
};

const setFaq = (faqs: any[]) => {
  return {
    type: constants.SET_FAQ,
    payload: faqs,
  };
};
const setAnnualReports = (anualReports: any[]) => {
  return {
    type: constants.SET_ANNUAL_REPORTS,
    payload: anualReports,
  };
};
const setLandingPage = (landingPage: any[]) => {
  return {
    type: constants.SET_LANDING_PAGE,
    payload: landingPage,
  };
};
const setCareers = (careers: any[]) => {
  return {
    type: constants.SET_CAREERS,
    payload: careers,
  };
};

const setCareersPageCount = (count: number) => {
  return {
    type: constants.SET_CAREERS_PAGE_COUNT,
    payload: count,
  };
};

const setCareerDetails = (careerDetails: any) => {
  return {
    type: constants.SET_CAREER_DETAILS,
    payload: careerDetails,
  };
};

const setNewsPageCount = (count: number) => {
  return {
    type: constants.SET_NEWS_PAGE_COUNT,
    payload: count,
  };
};

const setNews = (news: NewsItem[]) => {
  return {
    type: constants.SET_NEWS,
    payload: news,
  };
};

const setFeaturedNews = (news: NewsItem) => {
  return {
    type: constants.SET_FEATURED_NEWS,
    payload: news,
  };
};

const setAbout = (about: any) => {
  return {
    type: constants.SET_ABOUT,
    payload: about,
  };
};

const setNewsDetails = (news: NewsItem) => {
  return {
    type: constants.SET_NEWS_DETAILS,
    payload: news,
  };
};

const setPolicy = (policy: any) => {
  return {
    type: constants.SET_POLICY,
    payload: policy,
  };
};

const setContentConstant= (content: any) => {
  return {
    type: constants.SET_CONTENT_CONSTANT,
    payload: content,
  };
};

let lang = localStorage.getItem("lang") as string;

const config = {
  headers: {
    "Accept-Language": lang == null ? "ar" : lang,
  },
};

export const API_getUserProtectionGuide = () => {
  return async (dispatch: React.Dispatch<any>) => {
    try {
      const res = await axios.get(
        baseUrl + endPoints.company.userProtectionGuide,
        config
      );
      window
        .open(`https://mf.amanmicrofinance.com/${res.data.pdf}`, "_blank")
        ?.focus();
      dispatch(setUserProtectionGuide(res.data.pdf));
      // console.log("API_getUserProtectionGuide  ===>>>", res.data.pdf);
      // return res
    } catch (error) {
      // console.error("API_getUserProtectionGuide  ====>>>", error);
    }
  };
};

export const API_getSuccessStories = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const res = await axios.get(
        baseUrl + endPoints.content.successStories,
        config
      );
      dispatch(setSuccessStories(res.data.results.results));
      // console.log("API_getSuccessStories ===>>>", res.data.results.results);
      // return res
    } catch (error) {
      // console.error("API_getSuccessStories ====>>>", error);
    }
  };
};

export const API_getGovernments = () => {
  return async (dispatch: React.Dispatch<any>) => {
    try {
      lang = localStorage.getItem("lang") as string;
      const res = await axios.get(baseUrl + endPoints.company.branches, config);
      dispatch(setGovernments(res.data));
    } catch (error) {
      console.error("API_getGovernments ====>>>", error);
    }
  };
};

export const API_getConstant = () => {
  return async (dispatch: React.Dispatch<any>) => {
    try {
      lang = localStorage.getItem("lang") as string;
      const res = await axios.get(baseUrl + endPoints.content.cons,
         config);
      dispatch(setContentConstant(res.data[0]));
    } catch (error) {
      console.error("API_getConstant ====>>>", error);
    }
  };
};

export const API_PostContactUsReq = (data: ContactUsData) => {
  return async (_: React.Dispatch<any>) => {
    try {
      // console.log("data", data);
      let contact_us_data = new FormData();
      contact_us_data.append("full_name", data.full_name);
      contact_us_data.append("phone_number", data.phone_number);
      contact_us_data.append("address", data.address);
      contact_us_data.append("dob", data.dob);
      contact_us_data.append("government", String(data.government));
      contact_us_data.append("message", data.message);
      contact_us_data.append("type", data.type);

      const res = await axios.post(
        baseUrl + endPoints.application.contactUs,
        contact_us_data,
        config
      );
      // console.log("API_PostContactUsReq ====>>>", res.data);
      return res;
    } catch (error: any) {
      // console.log("API_PostContactUsReq ====>>>", error);
      return error.response;
    }
  };
};

export const API_GetFAQ = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const res = await axios.get(baseUrl + endPoints.content.FAQ, config);
      dispatch(setFaq(res.data));
      // console.log("API_GetFAQ ====>>>", res.data);
    } catch (error) {
      // console.log("API_GetFAQ ====>>>", error);
    }
  };
};

export const API_GetAnnualReports = () => {
  return async (dispatch: React.Dispatch<any>) => {
    try {
      const res = await axios.get(
        baseUrl + endPoints.content.annualReports,
        config
      );
      dispatch(setAnnualReports(res.data));
      // console.log("API_GetAnnualReports ====>>>", res.data);
    } catch (error) {
      // console.log("API_GetAnnualReports ====>>>", error);
    }
  };
};

export const API_GetLandingPage = () => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const res = await axios.get(
        baseUrl + endPoints.content.landingPage,
        config
      );
      dispatch(setLandingPage(res.data));
      // console.log("API_GetLandingPage ====>>> ", getState().settingsReducer.lang, res);
    } catch (error) {
      // console.log("API_GetLandingPage ====>>>", error);
    }
  };
};

export const API_GetCareers = (pageNumber: number, careerId?: number) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      const res = await axios.get(
        baseUrl + endPoints.content.careers + `?page=${pageNumber}`,
        config
      );
      // console.log("careers : ", res.data.results);
      dispatch(setCareers(res.data.results));
      dispatch(
        setCareersPageCount(Math.floor(res.data.count / res.data.page_size + 1))
      );
      // console.log("API_GetCareers ====>>>", res);
      if (careerId) {
        const res = await axios.get(
          baseUrl + endPoints.content.careers + `?id=${careerId}`,
          config
        );
        dispatch(setCareerDetails(res.data.results[0]));
        // console.log("API_GetCareersById ====>>>", res.data.results[0]);
      } else if (pageNumber) {
        const res = await axios.get(
          baseUrl + endPoints.content.careers + `?page=${pageNumber}`,
          config
        );
        dispatch(setCareers(res.data.results));
        dispatch(
          setCareersPageCount(
            Math.floor(res.data.count / res.data.page_size + 1)
          )
        );
        // console.log("API_GetCareers ====>>>", res.data);
      }
    } catch (error) {
      // console.log("API_GetCareers====>>>", error);
    }
  };
};

export const API_GetNews = (
  pageNumber: number,
  featured: boolean,
  bankRelated: any = null
) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      // const config = {
      //   headers: {
      //     "Accept-Language": getState().settingsReducer.lang,
      //   },
      // };

      if (featured) {
        const res = await axios.get(
          baseUrl + endPoints.company.news + `?featured=True`,
          config
        );
        dispatch(setFeaturedNews(res.data.results[0]));
        // console.log("API_GetFeatured ====>>>", res.data);
      } else {
        const res = await axios.get(
          baseUrl +
            endPoints.company.news +
            `?page=${pageNumber}${
              bankRelated === true
                ? "&bank_related=True"
                : bankRelated === false
                ? "&bank_related=False"
                : ""
            }&featured=False`,
          config
        );
        dispatch(setNewsPageCount(Math.floor(res.data.total_pages)));
        dispatch(setNews(res.data.results));
        // console.log("API_GetNews ====>>>", res.data);
      }
    } catch (error) {
      // console.log("API_GetNews ====>>>", error);
    }
  };
};

export const API_GetNewsDetails = (id: number | undefined = undefined) => {
  return async (dispatch: React.Dispatch<any>, getState: () => RootState) => {
    try {
      // const config = {
      //   headers: {
      //     "Accept-Language": getState().settingsReducer.lang,
      //   },
      // };
      const res = await axios.get(
        baseUrl + endPoints.company.news + `/${id}`,
        config
      );
      dispatch(setNewsDetails(res.data));
      // console.log("API_GetNewsDetails ====>>>", res.data);
    } catch (error) {
      // console.error("API_GetNewsDetails ====>>>", error);
    }
  };
};

export const API_GetPolicy = () => {
  return async (dispatch: React.Dispatch<any>) => {
    try {
      lang = localStorage.getItem("lang") as string;
      const res = await axios.get(baseUrl + endPoints.company.policy, config);
      // console.log(lang);
      dispatch(setPolicy(res.data));
      // console.log("API_GetPolicy ====>>>", res.data);
    } catch (error) {
      // console.error("API_GetPolicy ====>>>", error);
    }
  };
};

export const API_PostCareersReq = (data: CareersData) => {
  console.log("data of API_PostCareersReq : ", data);
  return async (_: React.Dispatch<any>) => {
    try {
      let applicantData = new FormData();
      applicantData.append("name", data.name);
      applicantData.append("mobile_number", data.mobile_number);
      applicantData.append("biography", data.biography);
      applicantData.append("email", data.email);
      applicantData.append("government", data.government);
      applicantData.append("branch", data.branch);
      applicantData.append("cv_file", data.cv_file);
      if (data.career != undefined) applicantData.append("career", data.career);

      const res = await axios.post(
        baseUrl + endPoints.content.careersApply,
        applicantData,
        config
      );
      // console.log("API_PostCareersReq ====>>>", res.data);
      return res;
    } catch (error: any) {
      // console.error("API_PostCareerReq ====>>>", error);
      return error.response;
    }
  };
};

export const API_GetAbout = () => {
  return async (dispatch: React.Dispatch<any>) => {
    try {
      lang = localStorage.getItem("lang") as string;
      const res = await axios.get(baseUrl + endPoints.company.about, config);
      dispatch(setAbout(res.data));
      // console.log("API_GetAbout ====>>>", res.data);
    } catch (error) {
      // console.log("API_GetAbout====>>>", error);
    }
  };
};
