import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { NewsItem } from "types";
interface IProps {
  news: NewsItem;
}
const NewsRow: React.FC<IProps> = ({ news }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadMore = () => {
    if (news.external_link) {
      window.location.replace(news.external_link);
    } else {
      navigate(`/news/${news.id}`);
    }
  };

  return (
    <Row className="news-row mb-4 p-0">
      <Col xs={12} lg={4} className={"p-0 news-col"}>
        <img src={news.image} className={"w-100 h-100 news-row-image"} />
      </Col>
      <Col xs={12} lg={8} className={"p-4 news-col"}>
        {news.bank_related && (
          <div className="news-banking mb-4">
            <span className="font light">{t("Banking news")}</span>
          </div>
        )}
        <h2 className="font bold mb-4">{news.header}</h2>
        <p className=" font light">{news.paragraph}</p>
        <button
          onClick={handleReadMore}
          className="amnmf-btn-outlined amnmf-btn-outlined-orange read-more-btn"
        >
          {t("read more")}
        </button>
      </Col>
    </Row>
  );
};
export default NewsRow;
