import { t } from "i18next";
import { RootState } from "store/configStore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DefaultMain from "layout";
import images from "assets/images";
import Loader from "components/Loader";
import NewsBanner from "./sections/newsBanner";
import LatestNews from "./sections/latestNews";
import { API_GetNews } from "store/actions/contentActions";
import { Helmet } from "react-helmet";

const News: React.FC = (props) => {
  const { news, featuredNews } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const { lang } = useSelector((state: RootState) => state.settingsReducer);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onRender = async () => {
    setIsLoading(true);
    await dispatch(API_GetNews(1, true));
    await dispatch(API_GetNews(1, false));
    setIsLoading(false);
  };

  useEffect(() => {
    onRender();
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>News - amanmicrofinance.com</title>
      </Helmet>
      <DefaultMain>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {featuredNews ? <NewsBanner news={featuredNews} /> : ""}
            <LatestNews news={news} />
            {news?.length === 0 && (
              <section className="empty-news__section">
                <section className="empty-news-content">
                  <img src={images.emptyNews} alt={"empty news icon"} />
                  <h2 className="font bold">{t("There is no news now")}</h2>
                  <p className="font light">
                    {t(
                      "Thank you for your interest in knowing what's new in our company"
                    )}
                  </p>
                </section>
              </section>
            )}
          </>
        )}
      </DefaultMain>
    </>
  );
};
export default News;
