import React, { useEffect } from "react";
import { Container, Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import images from "assets/images";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configStore";
import { setLang } from "store/actions/settingsActions";
import { NavLink, useNavigate } from "react-router-dom";
import NavDropMenu from "./NavDropMenu";
import {
  API_GetAnnualReports,
  API_GetLandingPage,
  API_getUserProtectionGuide,
} from "store/actions/contentActions";

interface AnnualReport {
  id: any;
  name: any;
  pdf: string;
}

const NavBar: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { annualReports, userProtectionGuide } = useSelector(
    (state: RootState) => state.contentReducer
  );

  useEffect(() => {
    dispatch(API_GetAnnualReports());
  }, [dispatch]);

  const { lang, width } = useSelector(
    (state: RootState) => state.settingsReducer
  );
  const { constants } = useSelector(
    (state: RootState) => state.contentReducer
  );
  const changeLang = (e: React.ChangeEvent<any>) => {
    const newLang = lang !== "ar" ? "ar" : "en";
    localStorage.setItem("lang", newLang);
    dispatch(setLang(newLang));
    dispatch(API_GetLandingPage());
    document.querySelector("html")!.setAttribute("lang", newLang);
    document
      .querySelector("html")!
      .setAttribute("dir", lang !== "ar" ? "rtl" : "ltr");
    i18n.changeLanguage(newLang);
  };
  return (
    <nav className="nav-container">
      <div className="topNav-container">
        <ul>
          <li>
            <Image
              src={images.topNavIcon}
              width={15}
              height={15}
              className="license-icon"
            />
            <h6>{t("Financial Supervisory Authority License No. 4")}</h6>
          </li>
        </ul>
        <ul className="topNav-items">
          <li>
            <button onClick={() => navigate("/news")}>{t("news")}</button>
          </li>
          <li>
            <button
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.amanmf&pli=1"
                )
              }
            >
              {t("Aman App")}
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/privacy")}>
              {t("Privacy Policy")}
            </button>
          </li>
          <li
            onClick={() => dispatch(API_getUserProtectionGuide())}
            style={{ cursor: "pointer" }}
          >
            <a
              style={{
                color: "inherit",
              }}
              className="user_guide_protect"
            >
              {t("Guide to protect customers in the non-banking sector")}{" "}
            </a>
          </li>
          <li>
            <button onClick={() => navigate("/careers")}>{t("Jobs")}</button>
          </li>
          <li>
            <button onClick={() => navigate("/contact-us")}>
              {t("Contact us")}
            </button>
          </li>
          {/* <li>
            <button className="font bold lang-btn" onClick={changeLang}>
              {t("lang")}
            </button>
          </li> */}
          {width > 1430 && (
            <li className="social-icons">
              <div className="nav-contact">
                <img
                  className="phone-icon"
                  src={images.phone}
                  width="25"
                  height="25"
                  alt=""
                />
                {constants?.phone}
              </div>
              <a
                href="https://www.linkedin.com/company/aman-holding-for-financials/"
                target="_blank"
              >
                <img
                  className="phone-icon"
                  src={images.linkedin}
                  width="25"
                  height="25"
                  alt=""
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UC2aKVBF0VNpLMbUv5TkkIcQ"
                target="_blank"
              >
                <img
                  className="phone-icon"
                  src={images.youtube}
                  width="25"
                  height="25"
                  alt=""
                />
              </a>
              <a
                href="https://web.whatsapp.com/send/?phone=20219910&text=hello&type=phone_number&app_absent=0"
                target="_blank"
              >
                <img
                  className="phone-icon"
                  src={images.whatsapp}
                  width="25"
                  height="25"
                  alt=""
                />
              </a>
              <a
                href="https://www.facebook.com/AmanforMicrofinance"
                target="_blank"
              >
                <img
                  className="phone-icon"
                  src={images.facebook}
                  width="25"
                  height="25"
                  alt=""
                />
              </a>
            </li>
          )}{" "}
        </ul>
      </div>

      <Navbar expand="lg">
        <Container className="navbar-container">
          <NavLink className="navbar-brand navbar-logo active" to="/">
            <img src="/aman-icon.png" width="50" height="50" alt="aman logo" />
            {width > 1400 && (
              <span className="font bold">{t("Aman microfinance")}</span>
            )}
          </NavLink>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav>
              <ul className="navbar-content">
                <NavLink className="nav-link font bold" to={"/about-us"}>
                  {t("About")}
                </NavLink>
                <NavLink className="nav-link font bold" to={"/branches"}>
                  {t("Aman offices")}
                </NavLink>
                <NavLink className="nav-link font bold" to={"/services"}>
                  {t("Services")}
                </NavLink>
                <NavDropMenu title={t("Financing Programs")}>
                  <NavLink
                    to={"/loan-request-online"}
                    className="nav-link font bold"
                  >
                    <p>{t("online merchants")}</p>
                  </NavLink>
                  <a href="/loan-request-sme" className="nav-link font bold">
                    <p>{t("Small and medium finance")}</p>
                  </a>
                  <a href="/loan-request-mf" className="nav-link font bold">
                    <p>{t("micro finance")}</p>
                  </a>
                  <a href="/light-vehicles" className="nav-link font bold">
                    <p>{t("Light vehicles")}</p>
                  </a>
                  <NavLink to={"/nano-lending"} className="nav-link font bold">
                    <p>{t("nano lending")}</p>
                  </NavLink>
                </NavDropMenu>

                <NavLink className="nav-link font bold" to={"/faq"}>
                  {t("questions and answers")}
                </NavLink>
                <NavDropMenu title={t("annual report")}>
                  {annualReports.map((el: AnnualReport) => (
                    <a
                      key={el.id}
                      href={el.pdf}
                      className="nav-link font bold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(`${el.name}`)}
                    </a>
                  ))}
                </NavDropMenu>
                <NavLink className="nav-link font bold" to={"/islamic-loan"}>
                  {t("Islamic Loan")}
                </NavLink>

                <NavLink className="nav-link font bold" to={"/smes-repayment"}>
                  {t("SMEs Repayments")}
                </NavLink>

                <NavLink className="nav-link font bold" to={"/contact-us"}>
                  {t("Contact us")}
                </NavLink>

                {width < 991.9 && (
                  <>
                    <NavLink
                      to={`/installments`}
                      className="nav-link font bold"
                    >
                      {t("Pay your installments")}
                    </NavLink>
                    <NavLink
                      to={`/loan-request-mf`}
                      className="nav-link font bold"
                    >
                      {t("Request for Funding")}
                    </NavLink>
                    {/* <button className="nav-link font bold lang-btn" onClick={changeLang}>
                      {t("lang")}
                    </button> */}
                  </>
                )}
              </ul>

              <div className="bottom-nav-Btns">
                {width > 1069 && (
                  <NavLink
                    to={`/loan-request-mf`}
                    className="navBtn-main font bold"
                  >
                    {t("Request for Funding")}
                  </NavLink>
                )}
                {width > 991.9 && (
                  <NavLink
                    to={`/installments`}
                    className="navBtn-sub font bold amnmf-btn-outlined"
                  >
                    {t("Pay your installments")}
                  </NavLink>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </nav>
  );
};

export default NavBar;
