import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import images from "assets/images";
import { RootState } from "store/configStore";

interface IProps {
  loading: boolean;
}

const Section2: React.FC<IProps> = ({ loading }) => {
  const { t } = useTranslation();
  const { about, constants } = useSelector((state: RootState) => state.contentReducer);

  return (
    <section className="aboutAman__statistics__section">
      <div className="d-flex col-sm-10 m-auto align-items-center justify-content-between">
        <div className="aboutAman__statistics__clientItem">
          <img
            src={images.activeClientImg}
            alt="Active client"
            className="w-100"
          />
          <div className="aboutAman__statistics__client__content w-100 text-white text-center">
            <img
              src={images.activeClientIcon}
              alt="Client"
              className="mb-4 logo"
            />
            <div className="content">
              {loading ? (
                <div>
                  <Spinner animation="border" variant="info" size={"sm"} />
                </div>
              ) : (
                <p className="font bold fs-5">{constants?.num_clients}</p>
              )}
              <p className="font">{t("Active clients")}</p>
            </div>
          </div>
        </div>
        <div className="aboutAman__statistics__branchesItem">
          <img src={images.branchesImg} alt="Branches" className="w-100" />
          <div className="aboutAman__statistics__branches__content w-100 text-white text-center">
            <img
              src={images.branchesIcon}
              alt="Branches"
              className="mb-4 logo"
            />
            <div className="content">
              {loading ? (
                <div>
                  <Spinner animation="border" variant="info" size={"sm"} />
                </div>
              ) : (
                <p className="font bold fs-5">{constants?.num_branches}</p>
              )}
              <p className="font bold">{t("Branches all over Egypt")}</p>
            </div>
          </div>
        </div>
      </div>
      <p className="font bold text-white text-center my-5 fs-5">
        {t("We aim to be the leader in microfinance")}
      </p>
    </section>
  );
};

export default Section2;
