import { rawHtmlToReact } from "helper/HtmlToReact";
import { useTranslation } from "react-i18next";

interface Props {
  nano: boolean;
  tabData: {
    id: number;
    question: string;
    answer: string;
    answer2: string;
  }[];
}

const TabContent: React.FC<Props> = ({ tabData, nano }) => {
  const { t } = useTranslation();

  return (
    <>
      {tabData?.map((item) => (
        <div key={item.id}>
          <h2 className="font bold">{t(item.question)}</h2>
          {nano ? (
            <ul>
              <li className="font light mb-3">{t(item.answer)}</li>
              {item.answer2 && (
                <li className="font light mb-3">{t(item.answer2)}</li>
              )}
            </ul>
          ) : (
            <p className="font light mb-3">{rawHtmlToReact(item.answer)}</p>
          )}
        </div>
      ))}
    </>
  );
};

export default TabContent;
