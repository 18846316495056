import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Branch, Government } from "types";
import images from "assets/images";
import { RootState } from "store/configStore";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  API_GetCareers,
  API_PostCareersReq,
} from "store/actions/contentActions";
import CommonOrangeButton from "components/Button";
import CommonModal from "components/CommonModal";

const MAX_BIO_LENGTH = 300;
const EgyptionNumbersRegex = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;

const CareersFormMain: React.FC = (props) => {
  const params = useParams();
  //   const [searchParams, setSearchParams] = useSearchParams();
  //   console.log("params", params.job);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileInput: any = useRef(null);
  const state = useLocation();

  const { lang } = useSelector((state: RootState) => state.settingsReducer);
  const { governments, careerDetails } = useSelector(
    (state: RootState) => state.contentReducer
  );

  const [isLoading, setIsLoading] = useState(false);
  const [displayedGovernments, setDisplayedGovernments] = useState<
    Government[]
  >([]);
  const [displayedBranches, setDisplayedBranches] = useState<Branch[]>([]);

  const [isSentSucc, setIsSentSucc] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //data states
  const [cvFileName, setCVFileName] = useState("");
  const [cvFileSize, setCVFileSize] = useState(0);
  const [cvFile, setCvFile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [government, setGovernment] = useState("0");
  const [branch, setBranch] = useState("0");
  const [mobileNumber, setMobileNumber] = useState("");
  const [biography, setBiography] = useState("");
  const [biographyLength, setBiographyLength] = useState(MAX_BIO_LENGTH);
  //validation states
  const [nameValidated, setNameValidated] = useState<boolean | undefined>(
    undefined
  );
  const [emailValidated, setEmailValidated] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<
    boolean | undefined
  >(undefined);

  const [fileValidated, setFileValidated] = useState(false);

  const handleFileInput = (event: SyntheticEvent) => {
    fileInput.current.click();
    event.stopPropagation();
  };

  const handleUploadFile = (event: any) => {
    const file = event.target.files[0];
    const name = file?.name.split(".");
    //<=50MB .pdf file
    if (file.size > 0 && file.size <= 50000000 && name[1] === "pdf") {
      setCVFileName(name[0]);
      setCVFileSize(Number((file.size / 1e6).toFixed(2)));
      setCvFile(file);
      setFileValidated(false);
    } else {
      setFileValidated(true);
    }
  };

  const handleName = (event: any) => {
    const name = event.target.value;
    setName(name);
  };

  const handleEmail = (event: any) => {
    const value = event.target.value.trim();
    setEmail(value);
    setEmailValidated(
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        event.target.value.trim()
      )
    );
  };
  const handleGovernment = (event: any) => {
    const index = event.target.value;
    setGovernment(index);
  };

  const handleBranch = (event: any) => {
    const index = event.target.value;
    setBranch(index);
  };

  const handleMobileNumber = (event: any) => {
    const value = event.target.value.trim();
    setMobileNumber(value);
  };

  const handleBiography = (event: any) => {
    const length = MAX_BIO_LENGTH - event.target.value.length;
    if (length >= 0) {
      setBiographyLength(length);
      setBiography(event.target.value);
    }
  };

  const isMissing = () => {
    return (
      !cvFileName ||
      !name ||
      !mobileNumber ||
      !email ||
      !nameValidated ||
      emailValidated ||
      !isPhoneNumberValid
    );
  };

  const clearForm = () => {
    setEmail("");
    setName("");
    setBiography("");
    setBiographyLength(MAX_BIO_LENGTH);
    setCVFileName("");
    setCVFileSize(0);
    setCvFile("");
    setGovernment("0");
    setBranch("0");
    setMobileNumber("");
  };

  const getGovernmentById = (id: string) => {
    return governments.filter(
      (element: Government) => element.id == Number(id)
    )[0];
  };

  const getBranchById = (id: string) => {
    return getGovernmentById(government).branches.filter(
      (element: Branch) => element.id == Number(id)
    )[0];
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    //validations
    setIsLoading(true);
    //API CALL
    let res: any;
    if (params.form === "jobs") {
      res = await dispatch(
        API_PostCareersReq({
          name,
          mobile_number: mobileNumber,
          cv_file: cvFile,
          email,
          government: getGovernmentById(government).government,
          branch: getBranchById(branch).name,
          biography,
          career: params.job,
        })
      );
    } else {
      res = await dispatch(
        API_PostCareersReq({
          name,
          mobile_number: mobileNumber,
          cv_file: cvFile,
          email,
          government: getGovernmentById(government).government,
          branch: getBranchById(branch).name,
          biography,
          career: params.job,
        })
      );
    }
    setIsModalOpen(true);
    setIsSentSucc(res.status >= 200 && res.status <= 299);
    setIsLoading(false);
    clearForm();
  };

  const onRender = () => {
    dispatch(API_GetCareers(1, Number(params.job)));
  };

  useEffect(() => {
    if (governments.length !== 0) {
      setDisplayedGovernments([
        {
          id: 0,
          government: t("Choose a Governorate.."),
        },
        ...governments,
      ]);
    }
  }, [governments]);

  useEffect(() => {
    if (government != "0") {
      const selectedGovernemateObject = getGovernmentById(government);
      setDisplayedBranches([
        {
          id: 0,
          name: t("Choose Branch.."),
        },
        ...selectedGovernemateObject.branches,
      ]);
    } else {
      setDisplayedBranches([]);
    }
  }, [government]);

  useEffect(() => {
    if (params.job !== "join-us") onRender();
  }, []);

  useEffect(() => {
    if (mobileNumber.length > 0)
      setIsPhoneNumberValid(EgyptionNumbersRegex.test(mobileNumber));
    else setIsPhoneNumberValid(undefined);
  }, [mobileNumber]);

  useEffect(() => {
    if (name.length > 0)
      setNameValidated(
        (/^[\u0621-\u064A| +]+$/.test(name.trim()) ||
          /^[a-zA-Z| +]+$/.test(name.trim())) &&
          name.trim().length > 10
      );
    else setNameValidated(undefined);
  }, [name]);

  return (
    <section className="careers-form-main__section">
      {params.job !== "join-us" && (
        <section className="careers-form-main__header">
          <h2 className="font bold">{careerDetails.header}</h2>
          <Link to="/careers" className="link">
            <p className="font bold">
              {t("Back to available jobs")}{" "}
              <img
                src={images.leftArrowSmall}
                alt={"left arrow"}
                className={lang === "en" ? "flippedIcon" : "link-icon"}
              />
            </p>
          </Link>
        </section>
      )}
      <Row className="form-main-section g-0">
        <Col xs={12} lg={7} className="form-first-column">
          <h3 className="font bold mb-3">{t("To Join Us")}</h3>
          <p className="font light">
            {t(
              "If you have the talent and want to join Aman microfinance team then send us your resume now"
            )}
          </p>
          <Form className="form-container">
            <Form.Group className="mb-3">
              <Form.Control
                type="file"
                ref={fileInput}
                className="form-file-input"
                onChange={handleUploadFile}
              />
              <div>
                {cvFileName ? (
                  <section className="file-input-container">
                    <section className="file-input-row">
                      <img src={images.pdfFile} alt={"pdf file"} />
                      <section className="mx-2">
                        <span className="font light mx-1 ">{cvFileName}</span>
                        <p className="font light file-input-text">
                          {cvFileSize + "MB"}
                        </p>
                      </section>
                    </section>
                    <img
                      src={images.editIcon}
                      alt={"edit icon"}
                      className={"edit-icon"}
                      onClick={handleFileInput}
                    />
                  </section>
                ) : (
                  <section className={`file-input`} onClick={handleFileInput}>
                    <img
                      src={images.upload}
                      alt={"upload"}
                      className={"upload-icon mx-1"}
                    />
                    <section className="file-input-text">
                      {/* <span className="font light mx-1 file-input-text">
                        {t("Drag a file or ")}
                      </span> */}
                      <span className="font bold">
                        {t("Upload your resume")}
                      </span>
                      <p className="font light">
                        {t("File size cannot exceed 50MB")}
                      </p>
                    </section>
                  </section>
                )}
              </div>
              <p
                className={`font light error-msg ${
                  fileValidated ? "show-error" : "hide-error"
                }`}
              >
                {t("Upload a pdf file with size less than 50MB")}
              </p>
            </Form.Group>

            <Row className="form-row">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-1">
                  <Form.Label className="font bold">
                    {t("*Fullname")}
                  </Form.Label>
                  <Form.Control
                    maxLength={100}
                    type="text"
                    placeholder={t("*Fullname")}
                    onChange={handleName}
                    value={name}
                  />
                  <p
                    className={`font light error-msg ${
                      !nameValidated && name.length !== 0
                        ? "show-error"
                        : "hide-error"
                    }`}
                  >
                    {t("Please enter valid full name")}
                  </p>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-1">
                  <Form.Label className="font bold">{t("Email*")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Email")}
                    onChange={handleEmail}
                    value={email}
                  />
                  <p
                    className={`font light error-msg ${
                      emailValidated ? "show-error" : "hide-error"
                    }`}
                  >
                    {t("Please provide a valid email")}
                  </p>
                </Form.Group>
              </Col>
            </Row>
            {/*
            <Row className="form-row">
              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label className="font bold">
                    {t("*Government")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Choose a government"
                    onChange={handleGovernment}
                    value={government}
                  >
                    {displayedGovernments.map(
                      (government: Government, index: number) => (
                        <option key={index} value={government.id}>
                          {government.name}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="mb-4">
                  <Form.Label className="font bold">{t("*Branch")}</Form.Label>
                  <Form.Select
                    aria-label="Choose a branch"
                    onChange={handleBranch}
                    value={branch}
                    disabled={displayedBranches.length == 0}
                  >
                    {displayedBranches.map((branch: Branch, index: number) => (
                      <option key={index} value={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}

            <Form.Group className="mb-1">
              <Form.Label className="font bold">
                {t("*Phone Number")}
              </Form.Label>
              <Form.Control
                maxLength={11}
                type="text"
                placeholder="01xxxxxxxxx"
                onChange={handleMobileNumber}
                value={mobileNumber}
              />
              <p
                className={`font light error-msg ${
                  !isPhoneNumberValid && mobileNumber.length !== 0
                    ? "show-error"
                    : "hide-error"
                }`}
              >
                {t("Please enter valid phone number")}
              </p>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="font bold">
                {t("Brief about you")}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                onChange={handleBiography}
                value={biography}
                className={"biography-area"}
              />
              <p className="font light mb-5 w-100 length-row">
                ({biographyLength})
              </p>
            </Form.Group>

            <CommonOrangeButton
              disabled={isMissing()}
              isSending={isLoading}
              onClick={handleSubmit}
              className="amnmf-btn-filled send-btn font bold"
            >
              {t("Send")}
            </CommonOrangeButton>
          </Form>
        </Col>

        <Col xs={12} lg={5} className="form-last-column">
          <img src={images.careers} className="form-image" />
        </Col>
      </Row>
      <CommonModal
        isSuccess={isSentSucc}
        successText="Your Application Has been sent successfully"
        errorText="An Error Occured Please try again"
        show={isModalOpen}
        handleClose={setIsModalOpen}
      />
    </section>
  );
};

export default CareersFormMain;
