import React from "react";
import { Row, Col } from "react-bootstrap";
import images from "../../../assets/images";
import { servicesContent } from "../../../content/services";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const SectionHeader: React.FC = (props) => {
  const { t } = useTranslation();
  return (
    <Row className="services-header r-h100">
      <Col className="right-section-header">
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="aos-init aos-animate"
        >
          <h1 className="font bold s45">{t(servicesContent.header1)}</h1>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="aos-init aos-animate"
        >
          <p className="font light">{t(servicesContent.sub1)}</p>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="aos-init aos-animate"
        >
          <div className="arrow">
            <a href="#" className="font light s18">
              {t(servicesContent.sub2)}
            </a>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="400"
          className="aos-init aos-animate"
        >
          <NavLink to={"/loan-request-mf"} className="font light s18">
            {t(servicesContent.btnTxt)}
            <img src={images.orangeArrow} width="35" height="35" alt="" />
          </NavLink>
          {/* <a href="#" className="font light s18">
            {t(servicesContent.btnTxt)}
            <img src={images.orangeArrow} width="35" height="35" alt="" />
          </a> */}
        </div>
      </Col>
      <Col
        data-aos="fade-up"
        data-aos-duration="400"
        className="services-img aos-init aos-animate"
      ></Col>
    </Row>
  );
};

export default SectionHeader;
